import { SelectOption } from 'components/common/CountrySelect/types';
import { CountryDto } from 'model/registration/registration-v3';
import { countryISOMapping } from './consts';
import { flags } from './flags';
import { errorCode, ErrorPayload } from './types';
import { getEnv } from '@ecovadis/infrastructure/env';
import jwt_decode from 'jwt-decode';

export const getCountryISO2 = (countryCode: string) => countryISOMapping[countryCode];

export const mapCountryPhoneCodesToOptions = (
  countries: CountryDto[],
  t: (key: string) => string,
): SelectOption<string>[] => {
  const mappedCountryCodes = [...countries]
    .sort((firstElement: any, secondElement: any) => firstElement?.name.localeCompare(secondElement?.name))
    .reduce((acc: SelectOption<string>[], nextValue: CountryDto) => {
      if (nextValue?.id) {
        const countryOption = nextValue?.phoneCodes
          ? nextValue?.phoneCodes.map((value: string) => ({
              label: `+${value} ` + (t ? t(`portal.registration.welcome.Country_${nextValue.id}`) : ''),
              value: { phoneCode: value, iso2Code: nextValue?.id },
              countryISO: nextValue.id,
              icon: nextValue.id ? (flags as any)[`flag_${nextValue.id}`] : null,
            }))
          : [];

        acc.push(countryOption as unknown as SelectOption<string>);
      }

      return acc;
    }, [])
    .flat();

  return mappedCountryCodes;
};

export const emailAlreadyExist = (errors: ErrorPayload) =>
  errors?.data?.some(errorObject => errorObject.errorCode === errorCode.EMAIL_ALREADY_EXISTS) || false;

export const containsDoubleDots = (value: string): { isValid: boolean; msg: string } => {
  if (value.trim() === '') {
    return { isValid: false, msg: 'this_field_is_required' };
  }

  const containsColon = /(:)/.test(value);

  return {
    isValid: !containsColon,
    msg: 'wrongUserNameFormat',
  };
};
export const getLoginPageUrl = () => {
  const environment = getEnv().REACT_APP_ENVIRONMENT;

  if (environment === 'prod') {
    return 'https://portal.ecovadis-survey.com/lite/#/';
  }

  return `https://portal-${environment}.ecovadis-itlab.com/lite/#/`;
};

export const getVitalsLoginPageUrl = () => getLoginPageUrl().replace('/lite/', '/lite-vitals/');

export const isSunriseProductType = (token?: string | null): boolean => {
  if (!token) return false;

  try {
    const data = jwt_decode<{ product_id?: string }>(token);

    return data?.product_id === 'Sunrise';
  } catch (e) {
    console.error('Invalid token - could not decode data');
  }

  return false;
};
