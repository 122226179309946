import React, { useContext, useEffect, useState } from 'react';
import {
  ConfirmationViewContainer,
  ConfirmationViewWrapper,
  EmailTextWrapper,
  ResendEmail,
  StyledBottomText,
  SubTextWrapper,
  TitleContainer,
} from './styles';
import { ReactComponent as EVSuccessImage } from '../../assets/images/success_img.svg';
import { useLocation } from 'react-router-dom';
import { useIntl } from '@ecovadis/infrastructure/i18n';
import { registrationAPIV3 } from 'model/registration/registration-v3';
import { SnackbarContext } from 'components/Snackbar/context/SnackbarContext';
import { RESEND_EMAIL_COUNTDOWN } from 'services/consts/registration';

type LocationState = {
  email: string;
  emailToken: string;
};

const ConfirmationView = () => {
  const [enableResendEmailBtn, setEnableResendEmailBtn] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(RESEND_EMAIL_COUNTDOWN);
  const location = useLocation();
  const { t } = useIntl();
  const translate = (key: string, values?: Record<string, string | number | JSX.Element> | undefined) =>
    t(`portal.registration.welcome.${key}`, values);
  const { email, emailToken } = location.state as LocationState;
  const snackBarContext = useContext(SnackbarContext);

  const [resendEmail, { isSuccess, isError, isFetching }] =
    registrationAPIV3?.useLazyPostV3RegistrationResendUserCreationConfirmationQuery();

  useEffect(() => {
    if (isSuccess) {
      snackBarContext.showSuccess(translate('Confirmationmailresent'));
    }
    if (isError) {
      snackBarContext.showError(translate('SorrywecouldntresendtheConfirmationemail'));
    }
  }, [isSuccess, isError]);

  const handleResendEmailClick = () => {
    resendEmail({ emailToken });
    if (isSuccess) {
      snackBarContext.showSuccess(translate('Confirmationmailresent'));
    }
    if (isError) {
      snackBarContext.showError(translate('SorrywecouldntresendtheConfirmationemail'));
    }
    setEnableResendEmailBtn(false);
    setCounter(RESEND_EMAIL_COUNTDOWN);
    setTimeout(() => setEnableResendEmailBtn(true), RESEND_EMAIL_COUNTDOWN * 1000);
  };

  useEffect(() => {
    setTimeout(() => setEnableResendEmailBtn(true), RESEND_EMAIL_COUNTDOWN * 1000);
  }, []);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <ConfirmationViewContainer>
      <ConfirmationViewWrapper>
        <TitleContainer>{translate('check_your_email')}</TitleContainer>
        <EVSuccessImage />
        <EmailTextWrapper>
          {translate('ClicktheConfirmationlinkinthe', {
            email: <strong data-testid="email-view">{email}</strong>,
          })}
        </EmailTextWrapper>
        <SubTextWrapper>
          <strong>{translate('Didntgettheemail')}</strong>
          <span>
            {translate('CheckYourPamFolderOrResendTheEmail', {
              /* @ts-ignore */
              p: chunks => <StyledBottomText counter={counter}>{chunks}</StyledBottomText>,
              /* @ts-ignore */
              b: chunks => <b>{chunks}</b>,
              /* @ts-ignore */
              a: chunks => (
                <ResendEmail
                  onClick={handleResendEmailClick}
                  disabled={!enableResendEmailBtn || isFetching}
                  data-testid="resend-email"
                >
                  {chunks}
                </ResendEmail>
              ),
              nbSeconds: counter === 0 ? '' : counter,
            })}
          </span>
        </SubTextWrapper>
      </ConfirmationViewWrapper>
    </ConfirmationViewContainer>
  );
};

export default ConfirmationView;
