import React from 'react';
import { CompanyWrapper, CompanySubText, InviteCompanyContainer, InviteCompanyText, ImageWrapper } from './styles';
import { useIntl } from '@ecovadis/infrastructure-i18n-files';
import { useSelector } from 'react-redux';
import { isRtl } from 'store/languageStore/selectors';
type CompanyViewProps = {
  legalEntityName: string;
  companyLogoUrl: string;
};
const CompanyView = ({ legalEntityName, companyLogoUrl }: CompanyViewProps) => {
  const { t } = useIntl();
  const isRtlDirection = useSelector(isRtl);

  return (
    <CompanyWrapper isRtl={isRtlDirection}>
      <InviteCompanyContainer>
        <CompanySubText>{t('portal.registration.welcome.InvitationRequestedBy')}</CompanySubText>
        <InviteCompanyText data-testid="requested-company">{legalEntityName}</InviteCompanyText>
      </InviteCompanyContainer>
      <ImageWrapper data-testid="company-logo" src={companyLogoUrl} alt="" />
    </CompanyWrapper>
  );
};

export default CompanyView;
