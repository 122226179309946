export enum SnackbarTypeEnum {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
}

export type SnackbarContextModel = {
  showSuccess: (message: string) => void;
  showError: (message?: string) => void;
  showInfo: (message: string) => void;
};
export type SnackbarStateModel = { type: SnackbarTypeEnum; message: string; open: boolean } | null;
