import React from 'react';
import { ErrorViewWrapper, Separator, SubTextWrapper, TitleContainer, TopTextWrapper } from './MessageBox.styles';
import { StyledButton } from 'components/common/Button/Button.styles';
import { getIdFomText } from 'services/utils/getId';
import { useSelector } from 'react-redux';
import { getCurrentLanguage } from 'store/languageStore/selectors';
import { getLinkFromObject } from 'services/utils/links';
import { ECOVADIS_WEBSITE_LINKS } from 'services/consts/links';

type MessageBoxProps = {
  title: string;
  message: string;
  buttonName: string;
};

export const MessageBox = ({ message, title, buttonName }: MessageBoxProps) => {
  const languageCodeState = useSelector(getCurrentLanguage);
  const ecovadisWebsiteLink = getLinkFromObject(ECOVADIS_WEBSITE_LINKS, 'ecovadisWebsite', languageCodeState);

  const handleClick = () => {
    const newTab = window.open();

    if (newTab !== null) {
      // same as rel="noopener" target="_blank" but programatically
      newTab.opener = null;
      newTab.location = ecovadisWebsiteLink;
    }
  };

  return (
    <ErrorViewWrapper>
      <TopTextWrapper>
        <TitleContainer id={getIdFomText(title)}>{title}</TitleContainer>
        <Separator />
      </TopTextWrapper>
      <SubTextWrapper id={getIdFomText(message)}>{message}</SubTextWrapper>
      <StyledButton data-testid="register-button" primary width={344} onClick={handleClick}>
        {buttonName}
      </StyledButton>
    </ErrorViewWrapper>
  );
};
