import { LanguageDto } from 'model/registration/registration-v3';
type languageOption = {
  label: string;
  value: string;
};

export const mapLanguagesToOptions = (
  languages: LanguageDto[],
  t: (id: string, values?: Record<string, string | number>) => string,
): languageOption[] =>
  languages.map(
    language =>
      ({
        label:
          language.id === 'pt'
            ? t(`portal.registration.welcome.Language_${language.name?.split(' (')[0]}`)
            : t(`portal.registration.welcome.Language_${language.name}`),
        value: language.id,
      }) as languageOption,
  );
export const getLanguageOptionById = (languageId: string, languagesOption: languageOption[]) =>
  languagesOption.find(lang => lang.value === languageId);
