import React from 'react';
import { CountryFlagContainer, CountryLabelWrapper } from './styles';

type CountryFlagRendererProps = {
  countryLabel: string;
  icon: any;
};

const CountryFlagRenderer = ({ countryLabel, icon: Icon }: CountryFlagRendererProps) => (
  <CountryFlagContainer>
    {Icon && <Icon width={25} />}
    <CountryLabelWrapper>{countryLabel}</CountryLabelWrapper>
  </CountryFlagContainer>
);

export default CountryFlagRenderer;
