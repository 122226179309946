import { emptySplitApiV3 as api } from '../config/emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    getV3Pendo: build.query<GetV3PendoApiResponse, GetV3PendoApiArg>({
      query: queryArg => ({ url: `/v3/Pendo`, params: { invitationToken: queryArg.invitationToken } }),
    }),
  }),
  overrideExisting: false,
});

export { injectedRtkApi as pendoAPI };
export type GetV3PendoApiResponse = /** status 200 Success */ PendoResponse;
export type GetV3PendoApiArg = {
  invitationToken?: string;
};
export type PendoProduct = 'Undefined' | 'CsrRating' | 'Sunrise';
export type PendoResponse = {
  pendoProduct?: PendoProduct;
};
export const { useGetV3PendoQuery } = injectedRtkApi;
