import * as queryString from 'query-string';

export const getInvitationId = (search: string) => {
  const urlParams = queryString.parse(search);

  return urlParams?.token;
};

export const getParam = (search: string, param: string) => {
  const urlParams = queryString.parse(search);

  return urlParams?.[param];
};
