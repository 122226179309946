import React from 'react';
import { useIntl } from '@ecovadis/infrastructure-i18n-files/dist';
import { StyledInvitationError } from './InvitationError.styles';
import { useLocation } from 'react-router-dom';
import { ErrorTypeEnum } from 'pages/Main/type';
import { MessageBox } from './MessageBox';

type InvitationLocationState = {
  errorType: ErrorTypeEnum;
};

export const InvitationError = () => {
  const { t } = useIntl();
  const location = useLocation();
  const { errorType } = location.state as InvitationLocationState;
  const translate = (key: string) => t(`portal.registration.welcome.${key}`);

  switch (errorType) {
    case ErrorTypeEnum.INVITATION_ALREADY_SUBMITTED:
      return (
        <StyledInvitationError>
          <MessageBox
            title={translate('NoMoreInformation')}
            message={translate('YourColleagueHasAlreadyProvided')}
            buttonName={translate('GoToEcovadisWebsite')}
          />
        </StyledInvitationError>
      );
    case ErrorTypeEnum.NVITATION_IS_CANCELLED:
      return (
        <StyledInvitationError>
          <MessageBox
            title={translate('ServiceNotAvailable')}
            message={translate('SorryRegistrationCannotBeCompleted')}
            buttonName={translate('GoToEcovadisWebsite')}
          />
        </StyledInvitationError>
      );
    default:
      return <></>;
  }
};
