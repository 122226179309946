import { styled } from '@ev-ui/core/common';
import { ReactComponent as Scroll } from '../../assets/images/svg/scroll.svg';

export const MainContainer = styled('div')(() => ({
  display: 'flex',
  width: '90%',
  margin: '0 auto',
  height: 'auto',
  '@media (max-width: 768px)': {
    flexDirection: 'column-reverse',
    width: '100%',
  },
  '@media (min-width: 1920px)': {
    width: '80%',
    margin: '0 auto',
  },
  '@media (min-width: 2740px)': {
    width: '50%',
    margin: '0 auto',
  },
}));

export const AppContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  background: `linear-gradient(#206061, #009976)`,
}));

export const ScrollImage = styled(Scroll)(() => ({
  position: 'fixed',
  left: '50%',
  bottom: '5%',
  cursor: 'pointer',
}));

export const ScrollWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'fixed',
  left: '50%',
  bottom: '5%',
  cursor: 'pointer',
  '@media (max-width: 768px)': {
    display: 'none',
  },
}));

export const ScrollText = styled('span')(({ theme }) => ({
  position: 'fixed',
  left: '50%',
  bottom: '5%',
  cursor: 'pointer',
  font: theme.evFonts.Noto12_Regular,
  color: theme.evColors.white,
}));

export const PaginationContainer = styled('div')(() => ({
  position: 'fixed',
  left: '3%',
  top: '50%',
  bottom: '50%',
  cursor: 'pointer',
  '@media (max-width: 768px)': {
    display: 'none',
  },
}));
