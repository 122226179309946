import { styled, TextField } from '@ev-ui/core/common';

export const EVAutoCompleteTextField = styled(TextField)<{ isvaluefilled: boolean }>(({ theme, isvaluefilled }) => ({
  width: 'inherit',
  '&.MuiFormLabel-root .Mui-error': {
    color: theme.evColors.grey600,
  },
  '&.MuiInputBase-root': {
    paddingRight: 20,
  },
  '& label, & label.Mui-focused': {
    marginTop: isvaluefilled ? 3 : 0,
  },
}));

export const CountryFlagContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const CountryLabelWrapper = styled('p')(({ theme }) => ({
  font: theme.evFonts.Noto12_Regular,
  paddingLeft: 10,
}));
