import React, { KeyboardEventHandler } from 'react';

import { TextFieldProps as TextFieldPropsEvUi } from '@ev-ui/core/common';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from '@ecovadis/infrastructure/i18n';
import { EVFormHelperText, EVInputFormControl, EVTextField } from './styles';
import { ErrorMessages } from '../consts';
type TextFieldProps = {
  fieldName: string;
  required?: boolean;
} & Omit<TextFieldPropsEvUi, 'value' | 'onBlur' | 'onChange'>;
const InputText = (props: TextFieldProps) => {
  const { t } = useIntl();
  const { fieldName, required, ...forwardProps } = props;
  const { control, register } = useFormContext();
  const blockInvalidChar: KeyboardEventHandler<HTMLDivElement> = e => {
    if (forwardProps?.type === 'number' && ['e', 'E', '+', '-'].includes(e.key)) {
      e.preventDefault();

      return;
    }
  };

  return (
    <Controller
      name={fieldName}
      control={control}
      {...register}
      rules={{
        required: required ? t(ErrorMessages.REQUIRED) : false,
      }}
      render={({ field, fieldState }) => (
        <EVInputFormControl error={Boolean(fieldState.error)}>
          <EVTextField
            error={Boolean(fieldState.error)}
            {...forwardProps}
            fullWidth
            name={props.fieldName}
            value={field.value ?? ''}
            onBlur={field.onBlur}
            onChange={field.onChange}
            onKeyDown={blockInvalidChar}
            autoComplete={props.fieldName}
            helperText={
              <EVFormHelperText data-testid={`${props.fieldName}-error`}>{fieldState.error?.message}</EVFormHelperText>
            }
          />
        </EVInputFormControl>
      )}
    />
  );
};

export default InputText;
