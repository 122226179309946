import { styled } from '@ev-ui/core/common';

export const StyledInvitationError = styled('div')(({ theme }) => ({
  backgroundColor: theme.evColors.grey100,
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 25,
}));
