import { styled } from '@ev-ui/core/common';

export const ConfirmationViewContainer = styled('div')(() => ({
  height: '100vh',
  background: `linear-gradient(#206061, #009976)`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 25,
}));

export const ConfirmationViewWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.evColors.white,
  borderRadius: '5%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  maxWidth: '27rem',
  padding: '2rem 1.5rem',
}));

export const TitleContainer = styled('p')(({ theme }) => ({
  font: theme.evFonts.Noto24_Bold,
  textAlign: 'center',
}));

export const EmailTextWrapper = styled('p')(({ theme }) => ({
  font: theme.evFonts.Noto14_Regular,
  textAlign: 'center',
}));

export const SubTextWrapper = styled('p')(({ theme }) => ({
  font: theme.evFonts.Noto14_Regular,
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  '@media (max-width: 768px)': {
    width: '50%',
  },
}));

export const StyledText = styled('p')(({ theme }) => ({
  font: theme.evFonts.Noto14_Regular,
}));

export const StyledBottomText = styled('p')<{ counter: number }>(({ theme, counter }) => ({
  font: theme.evFonts.Noto14_Regular,
  margin: '2px',
  '&:nth-of-type(3)': {
    font: theme.evFonts.Noto12_Regular,
    color: theme.evColors.grey600,
    display: counter === 0 ? 'none' : 'block',
  },
}));

export const ResendEmailTimeOut = styled('span')(({ theme }) => ({
  font: theme.evFonts.Noto12_Regular,
  color: theme.evColors.grey600,
}));

export const ResendEmail = styled('button')(({ theme }) => ({
  all: 'unset',
  font: theme.evFonts.Noto14_Regular,
  color: theme.evColors.primaryTurquoise,
  textDecoration: 'underline',
  cursor: 'pointer',
  margin: '0 0 15px',
  '&:disabled': {
    opacity: 0.4,
  },
}));
