import { styled } from '@ev-ui/core/common';

export const StyledMessageBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.evColors.white,
}));

export const ErrorViewWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.evColors.white,
  borderRadius: '5%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
  maxWidth: '62.5rem',
  padding: '20px 0px 50px 0px',
  height: '22.938rem',
}));

export const TopTextWrapper = styled('div')(() => ({
  width: '80%',
}));

export const Separator = styled('hr')(({ theme }) => ({
  borderBottom: `1px solid ${theme.evColors.grey300}`,
  width: '80%',
}));

export const TitleContainer = styled('p')(({ theme }) => ({
  font: theme.evFonts.Noto24_Bold,
  textAlign: 'center',
}));

export const SubTextWrapper = styled('p')(({ theme }) => ({
  font: theme.evFonts.Noto14_Regular,
  width: '80%',
  textAlign: 'center',
}));
