import { useEffect, useCallback } from 'react';
import { HOTJAR_SCRIPT_ID } from 'services/consts/analytics';
import { getEnv } from '@ecovadis/infrastructure/env';

export const useHotjar = () => {
  const hotjarInitialize = useCallback(async () => {
    if (!document.getElementById(HOTJAR_SCRIPT_ID)) {
      const script = document.createElement('script');

      script.id = HOTJAR_SCRIPT_ID;
      script.innerHTML = `
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:570616,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
       })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');

      `;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (getEnv().REACT_APP_IS_ANALYTICS_ENVIRONMENT) {
      hotjarInitialize();
    }
  }, [hotjarInitialize]);
};
