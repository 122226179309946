import React from 'react';
import { ThemeProvider } from '@emotion/react';

import { EvTheme, useTheme } from '@ev-ui/core/common';

const EmotionThemeProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const theme = useTheme<EvTheme>();

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default EmotionThemeProvider;
