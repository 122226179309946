import { emptySplitApiV3 as api } from '../config/emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    getV3Pendo: build.query<GetV3PendoApiResponse, GetV3PendoApiArg>({
      query: queryArg => ({ url: `/v3/Pendo`, params: { invitationToken: queryArg.invitationToken } }),
    }),
    getV3RegistrationUserRegistration: build.query<
      GetV3RegistrationUserRegistrationApiResponse,
      GetV3RegistrationUserRegistrationApiArg
    >({
      query: queryArg => ({
        url: `/v3/Registration/UserRegistration`,
        params: { invitationToken: queryArg.invitationToken },
      }),
    }),
    postV3RegistrationRegistration: build.query<
      PostV3RegistrationRegistrationApiResponse,
      PostV3RegistrationRegistrationApiArg
    >({
      query: queryArg => ({ url: `/v3/Registration/Registration`, method: 'POST', body: queryArg.registrationSubmit }),
    }),
    getV3RegistrationLoginWithInvitationToken: build.query<
      GetV3RegistrationLoginWithInvitationTokenApiResponse,
      GetV3RegistrationLoginWithInvitationTokenApiArg
    >({
      query: queryArg => ({
        url: `/v3/Registration/login_with_invitation_token`,
        params: { invitationToken: queryArg.invitationToken },
      }),
    }),
    postV3RegistrationResendUserCreationConfirmation: build.query<
      PostV3RegistrationResendUserCreationConfirmationApiResponse,
      PostV3RegistrationResendUserCreationConfirmationApiArg
    >({
      query: queryArg => ({
        url: `/v3/Registration/ResendUserCreationConfirmation`,
        method: 'POST',
        params: { emailToken: queryArg.emailToken },
      }),
    }),
    getV3RegistrationLogin: build.query<GetV3RegistrationLoginApiResponse, GetV3RegistrationLoginApiArg>({
      query: queryArg => ({ url: `/v3/Registration/login`, params: { invitationToken: queryArg.invitationToken } }),
    }),
  }),
  overrideExisting: false,
});

export { injectedRtkApi as registrationAPIV3 };

export type GetV3PendoApiResponse = /** status 200 Success */ PendoResponse;
export type GetV3PendoApiArg = {
  invitationToken?: string;
};
export type GetV3RegistrationUserRegistrationApiResponse = /** status 200 Success */ UserRegistrationResponse;
export type GetV3RegistrationUserRegistrationApiArg = {
  invitationToken?: string;
};
export type PostV3RegistrationRegistrationApiResponse = /** status 200 Success */ string;
export type PostV3RegistrationRegistrationApiArg = {
  registrationSubmit: RegistrationSubmit;
};
export type GetV3RegistrationLoginWithInvitationTokenApiResponse = unknown;
export type GetV3RegistrationLoginWithInvitationTokenApiArg = {
  invitationToken?: string;
};
export type PostV3RegistrationResendUserCreationConfirmationApiResponse = unknown;
export type PostV3RegistrationResendUserCreationConfirmationApiArg = {
  emailToken?: string;
};
export type GetV3RegistrationLoginApiResponse = unknown;
export type GetV3RegistrationLoginApiArg = {
  invitationToken?: string;
};
export type PendoProduct = 'Undefined' | 'CsrRating' | 'Sunrise';
export type PendoResponse = {
  pendoProduct?: PendoProduct;
};
export type PhoneView = {
  phoneCode?: string | null;
  iso2Code?: string | null;
  phoneNumber?: string | null;
};
export type InvitationDataView = {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  companyName?: string | null;
  phoneNumber?: PhoneView;
};
export type InvitingCompanyDataView = {
  invitingCompanyName?: string | null;
  invitingCompanyLogoUrl?: string | null;
};
export type UserRegistrationFormSettingsView = {
  hideLoginButton?: boolean;
  hideSubscriptionInformation?: boolean;
  isEmailMutable?: boolean;
  isCompanyNameMutable?: boolean;
};
export type LanguageDto = {
  id?: string | null;
  name?: string | null;
  cultureString?: string | null;
};
export type CountryDto = {
  id?: string | null;
  iso3Code?: string | null;
  name?: string | null;
  phoneCodes?: string[] | null;
};
export type UserRegistrationResponse = {
  invitationData?: InvitationDataView;
  invitingCompanyData?: InvitingCompanyDataView;
  registrationFormSettings?: UserRegistrationFormSettingsView;
  languages?: LanguageDto[] | null;
  countries?: CountryDto[] | null;
};
export type IOutputFormatter = object;
export type MemberTypes =
  | 'Constructor'
  | 'Event'
  | 'Field'
  | 'Method'
  | 'Property'
  | 'TypeInfo'
  | 'Custom'
  | 'NestedType'
  | 'All';
export type MethodAttributes =
  | 'ReuseSlot'
  | 'Private'
  | 'FamANDAssem'
  | 'Assembly'
  | 'Family'
  | 'FamORAssem'
  | 'Public'
  | 'MemberAccessMask'
  | 'UnmanagedExport'
  | 'Static'
  | 'Final'
  | 'Virtual'
  | 'HideBySig'
  | 'NewSlot'
  | 'CheckAccessOnOverride'
  | 'Abstract'
  | 'SpecialName'
  | 'RTSpecialName'
  | 'PinvokeImpl'
  | 'HasSecurity'
  | 'RequireSecObject'
  | 'ReservedMask';
export type MethodImplAttributes =
  | 'IL'
  | 'Native'
  | 'OPTIL'
  | 'Runtime'
  | 'Unmanaged'
  | 'NoInlining'
  | 'ForwardRef'
  | 'Synchronized'
  | 'NoOptimization'
  | 'PreserveSig'
  | 'AggressiveInlining'
  | 'AggressiveOptimization'
  | 'InternalCall'
  | 'MaxMethodImplVal';
export type CallingConventions = 'Standard' | 'VarArgs' | 'Any' | 'HasThis' | 'ExplicitThis';
export type IntPtr = object;
export type RuntimeMethodHandle = {
  value?: IntPtr;
};
export type MethodBase = {
  memberType?: MemberTypes;
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  metadataToken?: number;
  attributes?: MethodAttributes;
  methodImplementationFlags?: MethodImplAttributes;
  callingConvention?: CallingConventions;
  isAbstract?: boolean;
  isConstructor?: boolean;
  isFinal?: boolean;
  isHideBySig?: boolean;
  isSpecialName?: boolean;
  isStatic?: boolean;
  isVirtual?: boolean;
  isAssembly?: boolean;
  isFamily?: boolean;
  isFamilyAndAssembly?: boolean;
  isFamilyOrAssembly?: boolean;
  isPrivate?: boolean;
  isPublic?: boolean;
  isConstructedGenericMethod?: boolean;
  isGenericMethod?: boolean;
  isGenericMethodDefinition?: boolean;
  containsGenericParameters?: boolean;
  methodHandle?: RuntimeMethodHandle;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
};
export type GenericParameterAttributes =
  | 'None'
  | 'Covariant'
  | 'Contravariant'
  | 'VarianceMask'
  | 'ReferenceTypeConstraint'
  | 'NotNullableValueTypeConstraint'
  | 'DefaultConstructorConstraint'
  | 'SpecialConstraintMask';
export type TypeAttributes =
  | 'NotPublic'
  | 'Public'
  | 'NestedPublic'
  | 'NestedPrivate'
  | 'NestedFamily'
  | 'NestedAssembly'
  | 'NestedFamANDAssem'
  | 'NestedFamORAssem'
  | 'SequentialLayout'
  | 'ExplicitLayout'
  | 'LayoutMask'
  | 'Interface'
  | 'Abstract'
  | 'Sealed'
  | 'SpecialName'
  | 'RTSpecialName'
  | 'Import'
  | 'Serializable'
  | 'WindowsRuntime'
  | 'UnicodeClass'
  | 'AutoClass'
  | 'CustomFormatClass'
  | 'HasSecurity'
  | 'ReservedMask'
  | 'BeforeFieldInit'
  | 'CustomFormatMask';
export type LayoutKind = 'Sequential' | 'Explicit' | 'Auto';
export type StructLayoutAttribute = {
  typeId?: any | null;
  value?: LayoutKind;
};
export type RuntimeTypeHandle = {
  value?: IntPtr;
};
export type EventAttributes = 'None' | 'SpecialName' | 'RTSpecialName';
export type ParameterAttributes =
  | 'None'
  | 'In'
  | 'Out'
  | 'Lcid'
  | 'Retval'
  | 'Optional'
  | 'HasDefault'
  | 'HasFieldMarshal'
  | 'Reserved3'
  | 'Reserved4'
  | 'ReservedMask';
export type MemberInfo = {
  memberType?: MemberTypes;
  declaringType?: Type;
  reflectedType?: Type;
  name?: string | null;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  metadataToken?: number;
};
export type ParameterInfo = {
  attributes?: ParameterAttributes;
  member?: MemberInfo;
  name?: string | null;
  parameterType?: Type;
  position?: number;
  isIn?: boolean;
  isLcid?: boolean;
  isOptional?: boolean;
  isOut?: boolean;
  isRetval?: boolean;
  defaultValue?: any | null;
  rawDefaultValue?: any | null;
  hasDefaultValue?: boolean;
  customAttributes?: CustomAttributeData[] | null;
  metadataToken?: number;
};
export type ICustomAttributeProvider = object;
export type MethodInfo = {
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  metadataToken?: number;
  attributes?: MethodAttributes;
  methodImplementationFlags?: MethodImplAttributes;
  callingConvention?: CallingConventions;
  isAbstract?: boolean;
  isConstructor?: boolean;
  isFinal?: boolean;
  isHideBySig?: boolean;
  isSpecialName?: boolean;
  isStatic?: boolean;
  isVirtual?: boolean;
  isAssembly?: boolean;
  isFamily?: boolean;
  isFamilyAndAssembly?: boolean;
  isFamilyOrAssembly?: boolean;
  isPrivate?: boolean;
  isPublic?: boolean;
  isConstructedGenericMethod?: boolean;
  isGenericMethod?: boolean;
  isGenericMethodDefinition?: boolean;
  containsGenericParameters?: boolean;
  methodHandle?: RuntimeMethodHandle;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  memberType?: MemberTypes;
  returnParameter?: ParameterInfo;
  returnType?: Type;
  returnTypeCustomAttributes?: ICustomAttributeProvider;
};
export type EventInfo = {
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  metadataToken?: number;
  memberType?: MemberTypes;
  attributes?: EventAttributes;
  isSpecialName?: boolean;
  addMethod?: MethodInfo;
  removeMethod?: MethodInfo;
  raiseMethod?: MethodInfo;
  isMulticast?: boolean;
  eventHandlerType?: Type;
};
export type FieldAttributes =
  | 'PrivateScope'
  | 'Private'
  | 'FamANDAssem'
  | 'Assembly'
  | 'Family'
  | 'FamORAssem'
  | 'Public'
  | 'FieldAccessMask'
  | 'Static'
  | 'InitOnly'
  | 'Literal'
  | 'NotSerialized'
  | 'HasFieldRVA'
  | 'SpecialName'
  | 'RTSpecialName'
  | 'HasFieldMarshal'
  | 'PinvokeImpl'
  | 'HasDefault'
  | 'ReservedMask';
export type RuntimeFieldHandle = {
  value?: IntPtr;
};
export type FieldInfo = {
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  metadataToken?: number;
  memberType?: MemberTypes;
  attributes?: FieldAttributes;
  fieldType?: Type;
  isInitOnly?: boolean;
  isLiteral?: boolean;
  isNotSerialized?: boolean;
  isPinvokeImpl?: boolean;
  isSpecialName?: boolean;
  isStatic?: boolean;
  isAssembly?: boolean;
  isFamily?: boolean;
  isFamilyAndAssembly?: boolean;
  isFamilyOrAssembly?: boolean;
  isPrivate?: boolean;
  isPublic?: boolean;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  fieldHandle?: RuntimeFieldHandle;
};
export type PropertyAttributes =
  | 'None'
  | 'SpecialName'
  | 'RTSpecialName'
  | 'HasDefault'
  | 'Reserved2'
  | 'Reserved3'
  | 'Reserved4'
  | 'ReservedMask';
export type PropertyInfo = {
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  metadataToken?: number;
  memberType?: MemberTypes;
  propertyType?: Type;
  attributes?: PropertyAttributes;
  isSpecialName?: boolean;
  canRead?: boolean;
  canWrite?: boolean;
  getMethod?: MethodInfo;
  setMethod?: MethodInfo;
};
export type TypeInfo = {
  name?: string | null;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  metadataToken?: number;
  isInterface?: boolean;
  memberType?: MemberTypes;
  namespace?: string | null;
  assemblyQualifiedName?: string | null;
  fullName?: string | null;
  assembly?: Assembly;
  module?: Module;
  isNested?: boolean;
  declaringType?: Type;
  declaringMethod?: MethodBase;
  reflectedType?: Type;
  underlyingSystemType?: Type;
  isTypeDefinition?: boolean;
  isArray?: boolean;
  isByRef?: boolean;
  isPointer?: boolean;
  isConstructedGenericType?: boolean;
  isGenericParameter?: boolean;
  isGenericTypeParameter?: boolean;
  isGenericMethodParameter?: boolean;
  isGenericType?: boolean;
  isGenericTypeDefinition?: boolean;
  isSZArray?: boolean;
  isVariableBoundArray?: boolean;
  isByRefLike?: boolean;
  hasElementType?: boolean;
  genericTypeArguments?: Type[] | null;
  genericParameterPosition?: number;
  genericParameterAttributes?: GenericParameterAttributes;
  attributes?: TypeAttributes;
  isAbstract?: boolean;
  isImport?: boolean;
  isSealed?: boolean;
  isSpecialName?: boolean;
  isClass?: boolean;
  isNestedAssembly?: boolean;
  isNestedFamANDAssem?: boolean;
  isNestedFamily?: boolean;
  isNestedFamORAssem?: boolean;
  isNestedPrivate?: boolean;
  isNestedPublic?: boolean;
  isNotPublic?: boolean;
  isPublic?: boolean;
  isAutoLayout?: boolean;
  isExplicitLayout?: boolean;
  isLayoutSequential?: boolean;
  isAnsiClass?: boolean;
  isAutoClass?: boolean;
  isUnicodeClass?: boolean;
  isCOMObject?: boolean;
  isContextful?: boolean;
  isEnum?: boolean;
  isMarshalByRef?: boolean;
  isPrimitive?: boolean;
  isValueType?: boolean;
  isSignatureType?: boolean;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  structLayoutAttribute?: StructLayoutAttribute;
  typeInitializer?: ConstructorInfo;
  typeHandle?: RuntimeTypeHandle;
  guid?: string;
  baseType?: Type;
  isSerializable?: boolean;
  containsGenericParameters?: boolean;
  isVisible?: boolean;
  genericTypeParameters?: Type[] | null;
  declaredConstructors?: ConstructorInfo[] | null;
  declaredEvents?: EventInfo[] | null;
  declaredFields?: FieldInfo[] | null;
  declaredMembers?: MemberInfo[] | null;
  declaredMethods?: MethodInfo[] | null;
  declaredNestedTypes?: TypeInfo[] | null;
  declaredProperties?: PropertyInfo[] | null;
  implementedInterfaces?: Type[] | null;
};
export type SecurityRuleSet = 'None' | 'Level1' | 'Level2';
export type Assembly = {
  definedTypes?: TypeInfo[] | null;
  exportedTypes?: Type[] | null;
  codeBase?: string | null;
  entryPoint?: MethodInfo;
  fullName?: string | null;
  imageRuntimeVersion?: string | null;
  isDynamic?: boolean;
  location?: string | null;
  reflectionOnly?: boolean;
  isCollectible?: boolean;
  isFullyTrusted?: boolean;
  customAttributes?: CustomAttributeData[] | null;
  escapedCodeBase?: string | null;
  manifestModule?: Module;
  modules?: Module[] | null;
  globalAssemblyCache?: boolean;
  hostContext?: number;
  securityRuleSet?: SecurityRuleSet;
};
export type ModuleHandle = {
  mdStreamVersion?: number;
};
export type Module = {
  assembly?: Assembly;
  fullyQualifiedName?: string | null;
  name?: string | null;
  mdStreamVersion?: number;
  moduleVersionId?: string;
  scopeName?: string | null;
  moduleHandle?: ModuleHandle;
  customAttributes?: CustomAttributeData[] | null;
  metadataToken?: number;
};
export type ConstructorInfo = {
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  metadataToken?: number;
  attributes?: MethodAttributes;
  methodImplementationFlags?: MethodImplAttributes;
  callingConvention?: CallingConventions;
  isAbstract?: boolean;
  isConstructor?: boolean;
  isFinal?: boolean;
  isHideBySig?: boolean;
  isSpecialName?: boolean;
  isStatic?: boolean;
  isVirtual?: boolean;
  isAssembly?: boolean;
  isFamily?: boolean;
  isFamilyAndAssembly?: boolean;
  isFamilyOrAssembly?: boolean;
  isPrivate?: boolean;
  isPublic?: boolean;
  isConstructedGenericMethod?: boolean;
  isGenericMethod?: boolean;
  isGenericMethodDefinition?: boolean;
  containsGenericParameters?: boolean;
  methodHandle?: RuntimeMethodHandle;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  memberType?: MemberTypes;
};
export type CustomAttributeTypedArgument = {
  argumentType?: Type;
  value?: any | null;
};
export type CustomAttributeNamedArgument = {
  memberInfo?: MemberInfo;
  typedValue?: CustomAttributeTypedArgument;
  memberName?: string | null;
  isField?: boolean;
};
export type CustomAttributeData = {
  attributeType?: Type;
  constructor?: ConstructorInfo;
  constructorArguments?: CustomAttributeTypedArgument[] | null;
  namedArguments?: CustomAttributeNamedArgument[] | null;
};
export type Type = {
  name?: string | null;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  metadataToken?: number;
  isInterface?: boolean;
  memberType?: MemberTypes;
  namespace?: string | null;
  assemblyQualifiedName?: string | null;
  fullName?: string | null;
  assembly?: Assembly;
  module?: Module;
  isNested?: boolean;
  declaringType?: Type;
  declaringMethod?: MethodBase;
  reflectedType?: Type;
  underlyingSystemType?: Type;
  isTypeDefinition?: boolean;
  isArray?: boolean;
  isByRef?: boolean;
  isPointer?: boolean;
  isConstructedGenericType?: boolean;
  isGenericParameter?: boolean;
  isGenericTypeParameter?: boolean;
  isGenericMethodParameter?: boolean;
  isGenericType?: boolean;
  isGenericTypeDefinition?: boolean;
  isSZArray?: boolean;
  isVariableBoundArray?: boolean;
  isByRefLike?: boolean;
  hasElementType?: boolean;
  genericTypeArguments?: Type[] | null;
  genericParameterPosition?: number;
  genericParameterAttributes?: GenericParameterAttributes;
  attributes?: TypeAttributes;
  isAbstract?: boolean;
  isImport?: boolean;
  isSealed?: boolean;
  isSpecialName?: boolean;
  isClass?: boolean;
  isNestedAssembly?: boolean;
  isNestedFamANDAssem?: boolean;
  isNestedFamily?: boolean;
  isNestedFamORAssem?: boolean;
  isNestedPrivate?: boolean;
  isNestedPublic?: boolean;
  isNotPublic?: boolean;
  isPublic?: boolean;
  isAutoLayout?: boolean;
  isExplicitLayout?: boolean;
  isLayoutSequential?: boolean;
  isAnsiClass?: boolean;
  isAutoClass?: boolean;
  isUnicodeClass?: boolean;
  isCOMObject?: boolean;
  isContextful?: boolean;
  isEnum?: boolean;
  isMarshalByRef?: boolean;
  isPrimitive?: boolean;
  isValueType?: boolean;
  isSignatureType?: boolean;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  structLayoutAttribute?: StructLayoutAttribute;
  typeInitializer?: ConstructorInfo;
  typeHandle?: RuntimeTypeHandle;
  guid?: string;
  baseType?: Type;
  isSerializable?: boolean;
  containsGenericParameters?: boolean;
  isVisible?: boolean;
};
export type UnauthorizedObjectResult = {
  value?: any | null;
  formatters?: IOutputFormatter[] | null;
  contentTypes?: string[] | null;
  declaredType?: Type;
  statusCode?: number | null;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type BadRequestObjectResult = {
  value?: any | null;
  formatters?: IOutputFormatter[] | null;
  contentTypes?: string[] | null;
  declaredType?: Type;
  statusCode?: number | null;
};
export type RegistrationSubmit = {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: PhoneView;
  preferredLanguageId?: string | null;
  companyName?: string | null;
  invitationToken?: string | null;
};
export const {
  useGetV3PendoQuery,
  useGetV3RegistrationUserRegistrationQuery,
  usePostV3RegistrationRegistrationQuery,
  useGetV3RegistrationLoginWithInvitationTokenQuery,
  usePostV3RegistrationResendUserCreationConfirmationQuery,
  useGetV3RegistrationLoginQuery,
} = injectedRtkApi;
