import { setWidowDimensions } from '../store/windowDimenionsStore/actions';
import { useDispatch } from 'react-redux';
import { useLayoutEffect } from 'react';

function useWindowDimenions() {
  const dispatch = useDispatch();

  const handleSize = () => {
    dispatch(
      setWidowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      }),
    );
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', handleSize);
    handleSize();

    return () => {
      window.removeEventListener('resize', handleSize);
    };
  }, []);
}

export default useWindowDimenions;
