import { Alert } from '@material-ui/lab';

import { EvTheme, Snackbar, styled } from '@ev-ui/core/common';
import styledEmotion from '@emotion/styled';
import { SnackbarTypeEnum } from './types';

const SnackbarColor = {
  [SnackbarTypeEnum.SUCCESS]: {
    backgroundColor: (theme: EvTheme) => theme.evColors.primaryTurquoise,
    color: (theme: EvTheme) => theme.evColors.white,
  },
  [SnackbarTypeEnum.ERROR]: {
    backgroundColor: (theme: EvTheme) => theme.evColors.primaryRed,
    color: (theme: EvTheme) => theme.evColors.white,
  },
  [SnackbarTypeEnum.INFO]: {
    backgroundColor: (theme: EvTheme) => theme.evColors.grey600,
    color: (theme: EvTheme) => theme.evColors.white,
  },
};

export const StyledSnackbar = styledEmotion(Snackbar)`
  .MuiPaper-root {
    min-width: 300px;
  }
`;

export const StyledAlert = styled(Alert)<{ type: SnackbarTypeEnum }>(({ theme, type }) => ({
  backgroundColor: SnackbarColor[type].backgroundColor(theme),
  color: SnackbarColor[type].color(theme),
  font: theme.evFonts.Noto16_Bold,
}));

export const StyledAlertBtn = styled('button')(({ theme }) => ({
  color: theme.evColors.white,
  font: theme.evFonts.Noto16_Bold,
  backgroundColor: 'transparent',
}));
