import { styled } from '@ev-ui/core/common';
export const HeaderText = styled('h1')(({ theme }) => ({
  fontSize: '3rem',
  fontWeight: 700,
  fontFamily: 'Noto Sans',
  color: theme.evColors.white,
  '@media (max-width: 768px)': {
    font: theme.evFonts.Noto28_Bold,
  },
}));

export const Header2Text = styled('h2')(({ theme }) => ({
  fontSize: '3rem',
  fontWeight: 700,
  fontFamily: 'Noto Sans',
  color: theme.evColors.white,
  '@media (max-width: 768px)': {
    font: theme.evFonts.Noto28_Bold,
  },
}));

export const SubText = styled('p')(({ theme }) => ({
  font: theme.evFonts.Noto24_Regular,
  color: theme.evColors.white,
  '@media (max-width: 768px)': {
    font: theme.evFonts.Noto18_Regular,
  },
}));

export const SubTextNoMargin = styled('span')(({ theme }) => ({
  font: theme.evFonts.Noto24_Regular,
  color: theme.evColors.white,
  '@media (max-width: 768px)': {
    font: theme.evFonts.Noto18_Regular,
  },
}));

export const StyledSectionContainer = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  width: '70%',
  display: 'flex',
  paddingTop: '10%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  margin: '50px 0px 50px 50px',
  '@media (max-width: 768px)': {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0',
    padding: '0px 25px 0px 25px',
    height: '100%',
  },
}));
