import React, { ChangeEvent, useEffect, useState } from 'react';
import { Popper, TextFieldProps as TextFieldPropsEvUi } from '@ev-ui/core/common';
import { AutocompleteInputChangeReason, Autocomplete, createFilterOptions } from '@material-ui/lab';
import { SelectOption } from './types';
import { EVAutoCompleteTextField } from './styles';
import CountryFlagRenderer from './CountryFlagRenderer';

export type SelectProps<T = string | number> = {
  label?: string;
  error?: boolean;
  required?: boolean;
  options: SelectOption<T>[];
  value?: SelectOption<T>;
  onChange?: (value: SelectOption<T> | null) => void;
  onManualChange?: (value: SelectOption<T> | null) => void;
  onBlur?: () => void;
  onSearchStarted?: (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => void;
  id?: string | number;
  testId?: string;
  limit?: number;
} & Omit<TextFieldPropsEvUi, 'value' | 'onBlur' | 'onChange'>;

function Select<T = string | number>(props: SelectProps<T>) {
  const { options, onSearchStarted, onChange, onBlur, onManualChange, id, value, testId, label, limit } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<SelectOption<T> | null>(null);
  const handleChange = (event: ChangeEvent<{}>, newValue: SelectOption<T> | null) => {
    onChange?.(newValue);
    onManualChange?.(newValue);
    setSelectedCountry?.(newValue);
  };

  const filterOptions = createFilterOptions<SelectOption<T>>({
    matchFrom: 'any',
    limit: limit || undefined,
  });

  useEffect(() => {
    if (value?.value?.iso2Code) {
      setSelectedCountry(value);
    }
  }, [value]);

  return (
    <Autocomplete
      options={options}
      open={isOpen}
      value={value}
      filterOptions={filterOptions}
      renderOption={option => <CountryFlagRenderer countryLabel={option.label} icon={option.icon} />}
      getOptionLabel={option => option.label}
      getOptionSelected={(option: SelectOption<T>, selectedValue: SelectOption<T>) =>
        option?.value?.phoneCode === selectedValue?.value?.phoneCode
      }
      renderInput={(params: JSX.IntrinsicAttributes & TextFieldPropsEvUi): JSX.Element => (
        <EVAutoCompleteTextField
          {...params}
          helperText={props.helperText}
          error={props.error}
          label={label}
          fullWidth
          onBlur={onBlur}
          id={id}
          data-testid={testId}
          isvaluefilled={selectedCountry !== null}
          InputProps={{
            ...params.InputProps,
            startAdornment: selectedCountry?.countryISO && (
              <CountryFlagRenderer data-testid="flag-renderer" countryLabel={''} icon={selectedCountry.icon} />
            ),
          }}
        />
      )}
      // @ts-expect-error the autocomplete has to be migrated to EV-UI's version
      PopperComponent={PopperProps => <Popper {...PopperProps} style={{ width: 250 }} placement="bottom-start" />}
      blurOnSelect
      disableClearable
      fullWidth
      onChange={handleChange}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      onBlur={onBlur}
      onInputChange={onSearchStarted}
      id={id}
    />
  );
}

export default React.memo(Select) as typeof Select;
