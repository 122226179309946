import '@ecovadis/infrastructure/vite/viteSetup';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { EvThemeProvider } from '@ev-ui/core/common';
import { ErrorHandlingProvider, environmentConfig } from '@ecovadis/infrastructure/providers';

import { App } from './App';
import { store } from './store';
import { EmotionThemeProvider } from 'providers';
import { initialiseApm } from '@ecovadis/infrastructure/monitoring';

initialiseApm({
  serviceName: 'portal-registration',
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);

const toRender = (
  <Provider store={store}>
    <EvThemeProvider environmentConfig={environmentConfig} disableLegacyColorMapping>
      <EmotionThemeProvider>
        <HashRouter>
          <ErrorHandlingProvider>
            <App />
          </ErrorHandlingProvider>
        </HashRouter>
      </EmotionThemeProvider>
    </EvThemeProvider>
  </Provider>
);

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MSW_MOCK) {
  import('./services/tests/mocks/browser').then(({ worker }) => {
    worker.start({
      onUnhandledRequest: 'warn',
    });

    root.render(toRender);
  });
} else {
  root.render(toRender);
}
