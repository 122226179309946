import React from 'react';
import { StyledSectionContainer } from './styles';

type SectionContainerProps = {
  children: any;
  id?: string;
};
const SectionContainer = ({ children, id }: SectionContainerProps) => (
  <StyledSectionContainer id={id}>{children}</StyledSectionContainer>
);

export default SectionContainer;
