import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  TypedUseSelectorHook,
  useSelector as useGenericSelector,
  useDispatch as useGenericDispatch,
} from 'react-redux';
import { rootMiddlewares } from './rootMiddleware';
import { rootReducer } from './rootReducers';

export const setupStore = <T>(initialState?: T) => {
  const newStore = configureStore({
    reducer: rootReducer,
    // @ts-ignore Looks like global cross app type issue
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(...rootMiddlewares),
    devTools: process.env.NODE_ENV === 'development',
    // @ts-ignore Looks like global cross app type issue
    preloadedState: initialState,
  });

  setupListeners(newStore.dispatch);

  return newStore;
};

export const store = setupStore();

// as per https://react-redux.js.org/using-react-redux/usage-with-typescript#define-typed-hooks
export const useSelector: TypedUseSelectorHook<AppState> = useGenericSelector;
export const useDispatch = useGenericDispatch;

export type AppState = ReturnType<typeof store.getState>;
