import React, { useMemo, useState } from 'react';

import { SnackbarCloseReason } from '@ev-ui/core/common';

import { SnackbarContext } from './context/SnackbarContext';
import { StyledAlert, StyledSnackbar } from './styles';

import { SnackbarContextModel, SnackbarStateModel, SnackbarTypeEnum } from './types';

export const SNACK_DURATION = 6000;

const SnackbarProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [snackbar, setSnackbar] = useState<SnackbarStateModel>(null);

  const contextValue: SnackbarContextModel = useMemo(
    () => ({
      showSuccess: (message: string) => setSnackbar({ message, type: SnackbarTypeEnum.SUCCESS, open: true }),
      showError: (message?: string) =>
        setSnackbar({ message: message ?? 'Error', type: SnackbarTypeEnum.ERROR, open: true }),
      showInfo: (message: string) => setSnackbar({ message, type: SnackbarTypeEnum.INFO, open: true }),
    }),
    [],
  );

  const handleCloseComponent = () => setSnackbar(state => (state ? { ...state, open: false } : null));

  const handleClose = (event: unknown, reason?: SnackbarCloseReason) => {
    if (reason === 'timeout') {
      handleCloseComponent();
    }
  };

  const handleExited = () => {
    setSnackbar(null);
  };

  const type = snackbar?.type ?? SnackbarTypeEnum.INFO;

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <StyledSnackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={SNACK_DURATION}
        data-testid={`snackbar-${type}`}
        open={Boolean(snackbar?.open)}
        message={snackbar?.message ?? ''}
        key={snackbar?.message}
        onClose={handleClose}
        onClick={handleCloseComponent}
        TransitionProps={{ onExited: handleExited }}
      >
        <StyledAlert severity={type} type={type} onClose={handleClose} icon={false}>
          {snackbar?.message}
        </StyledAlert>
      </StyledSnackbar>
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
