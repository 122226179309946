export const SUBSCRIPTION_LINKS = {
  compareSubscriptions: {
    en: 'https://ecovadis.com/plans-pricing/',
    fr: 'https://ecovadis.com/fr/plans-pricing/',
    de: 'https://ecovadis.com/de/plans-pricing/',
    nl: 'https://ecovadis.com/nl/plans-pricing/',
    ja: 'https://ecovadis.com/ja/plans-pricing/',
    it: 'https://ecovadis.com/it/plans-pricing/',
    pt: 'https://ecovadis.com/pt/plans-pricing/',
    ru: 'https://ecovadis.com/ru/plans-pricing/',
    es: 'https://ecovadis.com/es/plans-pricing/',
    zh: 'https://ecovadis.cn/plans-pricing/',
  },
};

export const ECOVADIS_WEBSITE_LINKS = {
  ecovadisWebsite: {
    en: 'https://ecovadis.com',
    fr: 'https://ecovadis.com/fr/',
    de: 'https://ecovadis.com/de/',
    nl: 'https://ecovadis.com/nl/',
    ja: 'https://ecovadis.com/ja/',
    it: 'https://ecovadis.com/it/',
    pt: 'https://ecovadis.com/pt/',
    ru: 'https://ecovadis.com/ru/',
    es: 'https://ecovadis.com/es/',
    zh: 'https://ecovadis.cn',
  },
};

export const SUPPORT_LINKS = {
  legalNoticeDataProtection: {
    en: 'https://support.ecovadis.com/hc/en-us/articles/115003531211',
    ar: 'https://support.ecovadis.com/hc/ar/articles/115003531211',
    de: 'https://support.ecovadis.com/hc/de/articles/115003531211',
    es: 'https://support.ecovadis.com/hc/es-es/articles/115003531211',
    fr: 'https://support.ecovadis.com/hc/fr/articles/115003531211',
    it: 'https://support.ecovadis.com/hc/it/articles/115003531211',
    ja: 'https://support.ecovadis.com/hc/ja/articles/115003531211',
    ko: 'https://support.ecovadis.com/hc/ko/articles/115003531211',
    nl: 'https://support.ecovadis.com/hc/nl/articles/115003531211',
    pt: 'https://support.ecovadis.com/hc/pt-br/articles/115003531211',
    ru: 'https://support.ecovadis.com/hc/ru/articles/115003531211',
    th: 'https://support.ecovadis.com/hc/th/articles/115003531211',
    tr: 'https://support.ecovadis.com/hc/tr/articles/115003531211',
    vi: 'https://support.ecovadis.com/hc/vi/articles/115003531211',
    zh: 'https://support.ecovadis.com/hc/zh-cn/articles/115003531211',
  },
};
