import { ErrorMessages } from 'components/common/consts';
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import CountrySelect, { SelectProps } from '../common/CountrySelect/Select';
import { SelectOption } from '../common/CountrySelect/types';
import { EVAutoCompleteFormControl } from './styles';
import { useIntl } from '@ecovadis/infrastructure/i18n';

type SelectInputProps = {
  fieldName: string;
  options: SelectOption[];
  testId?: string;
  onManualChange?: (option: string | null) => void;
  id?: string | number;
  limit?: number;
} & Omit<SelectProps<string>, 'value' | 'onBlur' | 'onChange' | 'options' | 'onManualChange'>;

const SelectInput = (props: SelectInputProps) => {
  const { t } = useIntl();
  const { fieldName, required, options, onManualChange, id, testId, limit, ...forwardProps } = props;
  const { control } = useFormContext();
  const advancedOptions: SelectOption<string>[] = useMemo(
    () =>
      options.map(option => ({
        value: option?.value,
        label: option.label,
        countryISO: option.countryISO,
        icon: option.icon,
      })),
    [options],
  ) as SelectOption<string>[];

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ required: required ? t(ErrorMessages.REQUIRED) : false }}
      render={({ field, fieldState }) => {
        const handleAdvancedChange = (newValue: SelectOption<string> | null) => {
          field.onChange(newValue ?? null);
        };

        return (
          <EVAutoCompleteFormControl error={Boolean(fieldState.error)}>
            <CountrySelect
              id={id}
              {...forwardProps}
              testId={testId}
              options={advancedOptions}
              value={field?.value}
              onBlur={field.onBlur}
              error={Boolean(fieldState.error)}
              onChange={handleAdvancedChange}
              limit={limit}
              helperText={<span data-testid={`${props.fieldName}-error`}>{fieldState.error?.message}</span>}
            />
          </EVAutoCompleteFormControl>
        );
      }}
    />
  );
};

export default SelectInput;
