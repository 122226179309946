import { ReactComponent as flag_AD } from '../../assets/images/svg/ad.svg';
import { ReactComponent as flag_AE } from '../../assets/images/svg/ae.svg';
import { ReactComponent as flag_AF } from '../../assets/images/svg/af.svg';
import { ReactComponent as flag_AG } from '../../assets/images/svg/ag.svg';
import { ReactComponent as flag_AI } from '../../assets/images/svg/ai.svg';
import { ReactComponent as flag_AL } from '../../assets/images/svg/al.svg';
import { ReactComponent as flag_AM } from '../../assets/images/svg/am.svg';
import { ReactComponent as flag_AO } from '../../assets/images/svg/ao.svg';
import { ReactComponent as flag_AQ } from '../../assets/images/svg/aq.svg';
import { ReactComponent as flag_AR } from '../../assets/images/svg/ar.svg';
import { ReactComponent as flag_AS } from '../../assets/images/svg/as.svg';
import { ReactComponent as flag_AT } from '../../assets/images/svg/at.svg';
import { ReactComponent as flag_AU } from '../../assets/images/svg/au.svg';
import { ReactComponent as flag_AW } from '../../assets/images/svg/aw.svg';
import { ReactComponent as flag_AX } from '../../assets/images/svg/ax.svg';
import { ReactComponent as flag_AZ } from '../../assets/images/svg/az.svg';
import { ReactComponent as flag_BA } from '../../assets/images/svg/ba.svg';
import { ReactComponent as flag_BB } from '../../assets/images/svg/bb.svg';
import { ReactComponent as flag_BD } from '../../assets/images/svg/bd.svg';
import { ReactComponent as flag_BE } from '../../assets/images/svg/be.svg';
import { ReactComponent as flag_BF } from '../../assets/images/svg/bf.svg';
import { ReactComponent as flag_BG } from '../../assets/images/svg/bg.svg';
import { ReactComponent as flag_BH } from '../../assets/images/svg/bh.svg';
import { ReactComponent as flag_BI } from '../../assets/images/svg/bi.svg';
import { ReactComponent as flag_BJ } from '../../assets/images/svg/bj.svg';
import { ReactComponent as flag_BL } from '../../assets/images/svg/bl.svg';
import { ReactComponent as flag_BM } from '../../assets/images/svg/bm.svg';
import { ReactComponent as flag_BN } from '../../assets/images/svg/bn.svg';
import { ReactComponent as flag_BO } from '../../assets/images/svg/bo.svg';
import { ReactComponent as flag_BQ } from '../../assets/images/svg/bq.svg';
import { ReactComponent as flag_BR } from '../../assets/images/svg/br.svg';
import { ReactComponent as flag_BS } from '../../assets/images/svg/bs.svg';
import { ReactComponent as flag_BT } from '../../assets/images/svg/bt.svg';
import { ReactComponent as flag_BV } from '../../assets/images/svg/bv.svg';
import { ReactComponent as flag_BW } from '../../assets/images/svg/bw.svg';
import { ReactComponent as flag_BY } from '../../assets/images/svg/by.svg';
import { ReactComponent as flag_BZ } from '../../assets/images/svg/bz.svg';
import { ReactComponent as flag_CA } from '../../assets/images/svg/ca.svg';
import { ReactComponent as flag_CC } from '../../assets/images/svg/cc.svg';
import { ReactComponent as flag_CD } from '../../assets/images/svg/cd.svg';
import { ReactComponent as flag_CF } from '../../assets/images/svg/cf.svg';
import { ReactComponent as flag_CG } from '../../assets/images/svg/cg.svg';
import { ReactComponent as flag_CH } from '../../assets/images/svg/ch.svg';
import { ReactComponent as flag_CI } from '../../assets/images/svg/ci.svg';
import { ReactComponent as flag_CK } from '../../assets/images/svg/ck.svg';
import { ReactComponent as flag_CL } from '../../assets/images/svg/cl.svg';
import { ReactComponent as flag_CM } from '../../assets/images/svg/cm.svg';
import { ReactComponent as flag_CN } from '../../assets/images/svg/cn.svg';
import { ReactComponent as flag_CO } from '../../assets/images/svg/co.svg';
import { ReactComponent as flag_CU } from '../../assets/images/svg/cu.svg';
import { ReactComponent as flag_CR } from '../../assets/images/svg/cr.svg';
import { ReactComponent as flag_CV } from '../../assets/images/svg/cv.svg';
import { ReactComponent as flag_CW } from '../../assets/images/svg/cw.svg';
import { ReactComponent as flag_CX } from '../../assets/images/svg/cx.svg';
import { ReactComponent as flag_CY } from '../../assets/images/svg/cy.svg';
import { ReactComponent as flag_CZ } from '../../assets/images/svg/cz.svg';
import { ReactComponent as flag_DE } from '../../assets/images/svg/de.svg';
import { ReactComponent as flag_DJ } from '../../assets/images/svg/dj.svg';
import { ReactComponent as flag_DK } from '../../assets/images/svg/dk.svg';
import { ReactComponent as flag_DM } from '../../assets/images/svg/dm.svg';
import { ReactComponent as flag_DO } from '../../assets/images/svg/do.svg';
import { ReactComponent as flag_DZ } from '../../assets/images/svg/dz.svg';
import { ReactComponent as flag_EC } from '../../assets/images/svg/ec.svg';
import { ReactComponent as flag_EE } from '../../assets/images/svg/ee.svg';
import { ReactComponent as flag_EG } from '../../assets/images/svg/eg.svg';
import { ReactComponent as flag_EH } from '../../assets/images/svg/eh.svg';
import { ReactComponent as flag_ER } from '../../assets/images/svg/er.svg';
import { ReactComponent as flag_ES } from '../../assets/images/svg/es.svg';
import { ReactComponent as flag_ET } from '../../assets/images/svg/et.svg';
import { ReactComponent as flag_EU } from '../../assets/images/svg/eu.svg';
import { ReactComponent as flag_FI } from '../../assets/images/svg/fi.svg';
import { ReactComponent as flag_FJ } from '../../assets/images/svg/fj.svg';
import { ReactComponent as flag_FK } from '../../assets/images/svg/fk.svg';
import { ReactComponent as flag_FM } from '../../assets/images/svg/fm.svg';
import { ReactComponent as flag_FO } from '../../assets/images/svg/fo.svg';
import { ReactComponent as flag_FR } from '../../assets/images/svg/fr.svg';
import { ReactComponent as flag_GA } from '../../assets/images/svg/ga.svg';
import { ReactComponent as flag_GB_ENG } from '../../assets/images/svg/gb-eng.svg';
import { ReactComponent as flag_GB_NIR } from '../../assets/images/svg/gb-nir.svg';
import { ReactComponent as flag_GB_SCT } from '../../assets/images/svg/gb-sct.svg';
import { ReactComponent as flag_GB_WLS } from '../../assets/images/svg/gb-wls.svg';
import { ReactComponent as flag_GB } from '../../assets/images/svg/gb.svg';
import { ReactComponent as flag_GD } from '../../assets/images/svg/gd.svg';
import { ReactComponent as flag_GE } from '../../assets/images/svg/ge.svg';
import { ReactComponent as flag_GF } from '../../assets/images/svg/gf.svg';
import { ReactComponent as flag_GG } from '../../assets/images/svg/gg.svg';
import { ReactComponent as flag_GH } from '../../assets/images/svg/gh.svg';
import { ReactComponent as flag_GI } from '../../assets/images/svg/gi.svg';
import { ReactComponent as flag_GL } from '../../assets/images/svg/gl.svg';
import { ReactComponent as flag_GM } from '../../assets/images/svg/gm.svg';
import { ReactComponent as flag_GN } from '../../assets/images/svg/gn.svg';
import { ReactComponent as flag_GP } from '../../assets/images/svg/gp.svg';
import { ReactComponent as flag_GQ } from '../../assets/images/svg/gq.svg';
import { ReactComponent as flag_GR } from '../../assets/images/svg/gr.svg';
import { ReactComponent as flag_GS } from '../../assets/images/svg/gs.svg';
import { ReactComponent as flag_GT } from '../../assets/images/svg/gt.svg';
import { ReactComponent as flag_GU } from '../../assets/images/svg/gu.svg';
import { ReactComponent as flag_GW } from '../../assets/images/svg/gw.svg';
import { ReactComponent as flag_GY } from '../../assets/images/svg/gy.svg';
import { ReactComponent as flag_HK } from '../../assets/images/svg/hk.svg';
import { ReactComponent as flag_HM } from '../../assets/images/svg/hm.svg';
import { ReactComponent as flag_HN } from '../../assets/images/svg/hn.svg';
import { ReactComponent as flag_HR } from '../../assets/images/svg/hr.svg';
import { ReactComponent as flag_HT } from '../../assets/images/svg/ht.svg';
import { ReactComponent as flag_HU } from '../../assets/images/svg/hu.svg';
import { ReactComponent as flag_ID } from '../../assets/images/svg/id.svg';
import { ReactComponent as flag_IE } from '../../assets/images/svg/ie.svg';
import { ReactComponent as flag_IL } from '../../assets/images/svg/il.svg';
import { ReactComponent as flag_IM } from '../../assets/images/svg/im.svg';
import { ReactComponent as flag_IN } from '../../assets/images/svg/in.svg';
import { ReactComponent as flag_IO } from '../../assets/images/svg/io.svg';
import { ReactComponent as flag_IQ } from '../../assets/images/svg/iq.svg';
import { ReactComponent as flag_IR } from '../../assets/images/svg/ir.svg';
import { ReactComponent as flag_IS } from '../../assets/images/svg/is.svg';
import { ReactComponent as flag_IT } from '../../assets/images/svg/it.svg';
import { ReactComponent as flag_JE } from '../../assets/images/svg/je.svg';
import { ReactComponent as flag_JM } from '../../assets/images/svg/jm.svg';
import { ReactComponent as flag_JO } from '../../assets/images/svg/jo.svg';
import { ReactComponent as flag_JP } from '../../assets/images/svg/jp.svg';
import { ReactComponent as flag_KE } from '../../assets/images/svg/ke.svg';
import { ReactComponent as flag_KG } from '../../assets/images/svg/kg.svg';
import { ReactComponent as flag_KH } from '../../assets/images/svg/kh.svg';
import { ReactComponent as flag_KI } from '../../assets/images/svg/ki.svg';
import { ReactComponent as flag_KM } from '../../assets/images/svg/km.svg';
import { ReactComponent as flag_KN } from '../../assets/images/svg/kn.svg';
import { ReactComponent as flag_KP } from '../../assets/images/svg/kp.svg';
import { ReactComponent as flag_KR } from '../../assets/images/svg/kr.svg';
import { ReactComponent as flag_KW } from '../../assets/images/svg/kw.svg';
import { ReactComponent as flag_KY } from '../../assets/images/svg/ky.svg';
import { ReactComponent as flag_KZ } from '../../assets/images/svg/kz.svg';
import { ReactComponent as flag_LA } from '../../assets/images/svg/la.svg';
import { ReactComponent as flag_LB } from '../../assets/images/svg/lb.svg';
import { ReactComponent as flag_LC } from '../../assets/images/svg/lc.svg';
import { ReactComponent as flag_LI } from '../../assets/images/svg/li.svg';
import { ReactComponent as flag_LK } from '../../assets/images/svg/lk.svg';
import { ReactComponent as flag_LR } from '../../assets/images/svg/lr.svg';
import { ReactComponent as flag_LS } from '../../assets/images/svg/ls.svg';
import { ReactComponent as flag_LT } from '../../assets/images/svg/lt.svg';
import { ReactComponent as flag_LU } from '../../assets/images/svg/lu.svg';
import { ReactComponent as flag_LV } from '../../assets/images/svg/lv.svg';
import { ReactComponent as flag_LY } from '../../assets/images/svg/ly.svg';
import { ReactComponent as flag_MA } from '../../assets/images/svg/ma.svg';
import { ReactComponent as flag_MC } from '../../assets/images/svg/mc.svg';
import { ReactComponent as flag_MD } from '../../assets/images/svg/md.svg';
import { ReactComponent as flag_ME } from '../../assets/images/svg/me.svg';
import { ReactComponent as flag_MF } from '../../assets/images/svg/mf.svg';
import { ReactComponent as flag_MG } from '../../assets/images/svg/mg.svg';
import { ReactComponent as flag_MH } from '../../assets/images/svg/mh.svg';
import { ReactComponent as flag_MK } from '../../assets/images/svg/mk.svg';
import { ReactComponent as flag_ML } from '../../assets/images/svg/ml.svg';
import { ReactComponent as flag_MM } from '../../assets/images/svg/mm.svg';
import { ReactComponent as flag_MN } from '../../assets/images/svg/mn.svg';
import { ReactComponent as flag_MO } from '../../assets/images/svg/mo.svg';
import { ReactComponent as flag_MP } from '../../assets/images/svg/mp.svg';
import { ReactComponent as flag_MQ } from '../../assets/images/svg/mq.svg';
import { ReactComponent as flag_MR } from '../../assets/images/svg/mr.svg';
import { ReactComponent as flag_MS } from '../../assets/images/svg/ms.svg';
import { ReactComponent as flag_MT } from '../../assets/images/svg/mt.svg';
import { ReactComponent as flag_MU } from '../../assets/images/svg/mu.svg';
import { ReactComponent as flag_MV } from '../../assets/images/svg/mv.svg';
import { ReactComponent as flag_MW } from '../../assets/images/svg/mw.svg';
import { ReactComponent as flag_MX } from '../../assets/images/svg/mx.svg';
import { ReactComponent as flag_MY } from '../../assets/images/svg/my.svg';
import { ReactComponent as flag_MZ } from '../../assets/images/svg/mz.svg';
import { ReactComponent as flag_NA } from '../../assets/images/svg/na.svg';
import { ReactComponent as flag_NC } from '../../assets/images/svg/nc.svg';
import { ReactComponent as flag_NE } from '../../assets/images/svg/ne.svg';
import { ReactComponent as flag_NF } from '../../assets/images/svg/nf.svg';
import { ReactComponent as flag_NG } from '../../assets/images/svg/ng.svg';
import { ReactComponent as flag_NI } from '../../assets/images/svg/ni.svg';
import { ReactComponent as flag_NL } from '../../assets/images/svg/nl.svg';
import { ReactComponent as flag_NO } from '../../assets/images/svg/no.svg';
import { ReactComponent as flag_NP } from '../../assets/images/svg/np.svg';
import { ReactComponent as flag_NR } from '../../assets/images/svg/nr.svg';
import { ReactComponent as flag_NU } from '../../assets/images/svg/nu.svg';
import { ReactComponent as flag_NZ } from '../../assets/images/svg/nz.svg';
import { ReactComponent as flag_OM } from '../../assets/images/svg/om.svg';
import { ReactComponent as flag_PA } from '../../assets/images/svg/pa.svg';
import { ReactComponent as flag_PE } from '../../assets/images/svg/pe.svg';
import { ReactComponent as flag_PF } from '../../assets/images/svg/pf.svg';
import { ReactComponent as flag_PG } from '../../assets/images/svg/pg.svg';
import { ReactComponent as flag_PH } from '../../assets/images/svg/ph.svg';
import { ReactComponent as flag_PK } from '../../assets/images/svg/pk.svg';
import { ReactComponent as flag_PL } from '../../assets/images/svg/pl.svg';
import { ReactComponent as flag_PM } from '../../assets/images/svg/pm.svg';
import { ReactComponent as flag_PN } from '../../assets/images/svg/pn.svg';
import { ReactComponent as flag_PR } from '../../assets/images/svg/pr.svg';
import { ReactComponent as flag_PS } from '../../assets/images/svg/ps.svg';
import { ReactComponent as flag_PT } from '../../assets/images/svg/pt.svg';
import { ReactComponent as flag_PW } from '../../assets/images/svg/pw.svg';
import { ReactComponent as flag_PY } from '../../assets/images/svg/py.svg';
import { ReactComponent as flag_QA } from '../../assets/images/svg/qa.svg';
import { ReactComponent as flag_RE } from '../../assets/images/svg/re.svg';
import { ReactComponent as flag_RO } from '../../assets/images/svg/ro.svg';
import { ReactComponent as flag_RS } from '../../assets/images/svg/rs.svg';
import { ReactComponent as flag_RU } from '../../assets/images/svg/ru.svg';
import { ReactComponent as flag_RW } from '../../assets/images/svg/rw.svg';
import { ReactComponent as flag_SA } from '../../assets/images/svg/sa.svg';
import { ReactComponent as flag_SB } from '../../assets/images/svg/sb.svg';
import { ReactComponent as flag_SC } from '../../assets/images/svg/sc.svg';
import { ReactComponent as flag_SD } from '../../assets/images/svg/sd.svg';
import { ReactComponent as flag_SE } from '../../assets/images/svg/se.svg';
import { ReactComponent as flag_SG } from '../../assets/images/svg/sg.svg';
import { ReactComponent as flag_SH } from '../../assets/images/svg/sh.svg';
import { ReactComponent as flag_SI } from '../../assets/images/svg/si.svg';
import { ReactComponent as flag_SJ } from '../../assets/images/svg/sj.svg';
import { ReactComponent as flag_SK } from '../../assets/images/svg/sk.svg';
import { ReactComponent as flag_SL } from '../../assets/images/svg/sl.svg';
import { ReactComponent as flag_SM } from '../../assets/images/svg/sm.svg';
import { ReactComponent as flag_SN } from '../../assets/images/svg/sn.svg';
import { ReactComponent as flag_SO } from '../../assets/images/svg/so.svg';
import { ReactComponent as flag_SR } from '../../assets/images/svg/sr.svg';
import { ReactComponent as flag_SS } from '../../assets/images/svg/ss.svg';
import { ReactComponent as flag_ST } from '../../assets/images/svg/st.svg';
import { ReactComponent as flag_SV } from '../../assets/images/svg/sv.svg';
import { ReactComponent as flag_SX } from '../../assets/images/svg/sx.svg';
import { ReactComponent as flag_SY } from '../../assets/images/svg/sy.svg';
import { ReactComponent as flag_SZ } from '../../assets/images/svg/sz.svg';
import { ReactComponent as flag_TC } from '../../assets/images/svg/tc.svg';
import { ReactComponent as flag_TD } from '../../assets/images/svg/td.svg';
import { ReactComponent as flag_TF } from '../../assets/images/svg/tf.svg';
import { ReactComponent as flag_TG } from '../../assets/images/svg/tg.svg';
import { ReactComponent as flag_TH } from '../../assets/images/svg/th.svg';
import { ReactComponent as flag_TJ } from '../../assets/images/svg/tj.svg';
import { ReactComponent as flag_TK } from '../../assets/images/svg/tk.svg';
import { ReactComponent as flag_TL } from '../../assets/images/svg/tl.svg';
import { ReactComponent as flag_TM } from '../../assets/images/svg/tm.svg';
import { ReactComponent as flag_TN } from '../../assets/images/svg/tn.svg';
import { ReactComponent as flag_TO } from '../../assets/images/svg/to.svg';
import { ReactComponent as flag_TR } from '../../assets/images/svg/tr.svg';
import { ReactComponent as flag_TT } from '../../assets/images/svg/tt.svg';
import { ReactComponent as flag_TV } from '../../assets/images/svg/tv.svg';
import { ReactComponent as flag_TW } from '../../assets/images/svg/tw.svg';
import { ReactComponent as flag_TZ } from '../../assets/images/svg/tz.svg';
import { ReactComponent as flag_UA } from '../../assets/images/svg/ua.svg';
import { ReactComponent as flag_UG } from '../../assets/images/svg/ug.svg';
import { ReactComponent as flag_UM } from '../../assets/images/svg/um.svg';
import { ReactComponent as flag_US } from '../../assets/images/svg/us.svg';
import { ReactComponent as flag_UY } from '../../assets/images/svg/uy.svg';
import { ReactComponent as flag_UZ } from '../../assets/images/svg/uz.svg';
import { ReactComponent as flag_VA } from '../../assets/images/svg/va.svg';
import { ReactComponent as flag_VC } from '../../assets/images/svg/vc.svg';
import { ReactComponent as flag_VE } from '../../assets/images/svg/ve.svg';
import { ReactComponent as flag_VG } from '../../assets/images/svg/vg.svg';
import { ReactComponent as flag_VI } from '../../assets/images/svg/vi.svg';
import { ReactComponent as flag_VN } from '../../assets/images/svg/vn.svg';
import { ReactComponent as flag_VU } from '../../assets/images/svg/vu.svg';
import { ReactComponent as flag_WF } from '../../assets/images/svg/wf.svg';
import { ReactComponent as flag_XK } from '../../assets/images/svg/xk.svg';
import { ReactComponent as flag_WS } from '../../assets/images/svg/ws.svg';
import { ReactComponent as flag_YE } from '../../assets/images/svg/ye.svg';
import { ReactComponent as flag_YT } from '../../assets/images/svg/yt.svg';
import { ReactComponent as flag_ZA } from '../../assets/images/svg/za.svg';
import { ReactComponent as flag_ZM } from '../../assets/images/svg/zm.svg';
import { ReactComponent as flag_ZW } from '../../assets/images/svg/zw.svg';
export const flags: Record<string, any> = {
  flag_AD,
  flag_AE,
  flag_AF,
  flag_AG,
  flag_AI,
  flag_AL,
  flag_AM,
  flag_AO,
  flag_AQ,
  flag_AR,
  flag_AS,
  flag_AT,
  flag_AU,
  flag_AW,
  flag_AX,
  flag_AZ,
  flag_BA,
  flag_BB,
  flag_BD,
  flag_BE,
  flag_BF,
  flag_BG,
  flag_BH,
  flag_BI,
  flag_BJ,
  flag_BL,
  flag_BM,
  flag_BN,
  flag_BO,
  flag_BQ,
  flag_BR,
  flag_BS,
  flag_BT,
  flag_BV,
  flag_BW,
  flag_BY,
  flag_BZ,
  flag_CA,
  flag_CC,
  flag_CD,
  flag_CF,
  flag_CG,
  flag_CH,
  flag_CI,
  flag_CK,
  flag_CL,
  flag_CM,
  flag_CN,
  flag_CO,
  flag_CU,
  flag_CR,
  flag_CV,
  flag_CW,
  flag_CX,
  flag_CY,
  flag_CZ,
  flag_DE,
  flag_DJ,
  flag_DK,
  flag_DM,
  flag_DO,
  flag_DZ,
  flag_EC,
  flag_EE,
  flag_EG,
  flag_EH,
  flag_ER,
  flag_ES,
  flag_ET,
  flag_EU,
  flag_FI,
  flag_FJ,
  flag_FK,
  flag_FM,
  flag_FO,
  flag_FR,
  flag_GA,
  flag_GB_ENG,
  flag_GB_NIR,
  flag_GB_SCT,
  flag_GB_WLS,
  flag_GB,
  flag_GD,
  flag_GE,
  flag_GF,
  flag_GG,
  flag_GH,
  flag_GI,
  flag_GL,
  flag_GM,
  flag_GN,
  flag_GP,
  flag_GQ,
  flag_GR,
  flag_GS,
  flag_GT,
  flag_GU,
  flag_GW,
  flag_GY,
  flag_HK,
  flag_HM,
  flag_HN,
  flag_HR,
  flag_HT,
  flag_HU,
  flag_ID,
  flag_IE,
  flag_IL,
  flag_IM,
  flag_IN,
  flag_IO,
  flag_IQ,
  flag_IR,
  flag_IS,
  flag_IT,
  flag_JE,
  flag_JM,
  flag_JO,
  flag_JP,
  flag_KE,
  flag_KG,
  flag_KH,
  flag_KI,
  flag_KM,
  flag_KN,
  flag_KP,
  flag_KR,
  flag_KW,
  flag_KY,
  flag_KZ,
  flag_LA,
  flag_LB,
  flag_LC,
  flag_LI,
  flag_LK,
  flag_LR,
  flag_LS,
  flag_LT,
  flag_LU,
  flag_LV,
  flag_LY,
  flag_MA,
  flag_MC,
  flag_MD,
  flag_ME,
  flag_MF,
  flag_MG,
  flag_MH,
  flag_MK,
  flag_ML,
  flag_MM,
  flag_MN,
  flag_MO,
  flag_MP,
  flag_MQ,
  flag_MR,
  flag_MS,
  flag_MT,
  flag_MU,
  flag_MV,
  flag_MW,
  flag_MX,
  flag_MY,
  flag_MZ,
  flag_NA,
  flag_NC,
  flag_NE,
  flag_NF,
  flag_NG,
  flag_NI,
  flag_NL,
  flag_NO,
  flag_NP,
  flag_NR,
  flag_NU,
  flag_NZ,
  flag_OM,
  flag_PA,
  flag_PE,
  flag_PF,
  flag_PG,
  flag_PH,
  flag_PK,
  flag_PL,
  flag_PM,
  flag_PN,
  flag_PR,
  flag_PS,
  flag_PT,
  flag_PW,
  flag_PY,
  flag_QA,
  flag_RE,
  flag_RO,
  flag_RS,
  flag_RU,
  flag_RW,
  flag_SA,
  flag_SB,
  flag_SC,
  flag_SD,
  flag_SE,
  flag_SG,
  flag_SH,
  flag_SI,
  flag_SJ,
  flag_SK,
  flag_SL,
  flag_SM,
  flag_SN,
  flag_SO,
  flag_SR,
  flag_SS,
  flag_ST,
  flag_SV,
  flag_SX,
  flag_SY,
  flag_SZ,
  flag_TC,
  flag_TD,
  flag_TF,
  flag_TG,
  flag_TH,
  flag_TJ,
  flag_TK,
  flag_TL,
  flag_TM,
  flag_TN,
  flag_TO,
  flag_TR,
  flag_TT,
  flag_TV,
  flag_TW,
  flag_TZ,
  flag_UA,
  flag_UG,
  flag_UM,
  flag_US,
  flag_UY,
  flag_UZ,
  flag_VA,
  flag_VC,
  flag_VE,
  flag_VG,
  flag_VI,
  flag_VN,
  flag_VU,
  flag_WF,
  flag_XK,
  flag_WS,
  flag_YE,
  flag_YT,
  flag_ZA,
  flag_ZM,
  flag_ZW,
};
