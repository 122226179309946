import { RegistrationSubmit } from 'model/registration/registration-v3';
import { ReactNode } from 'react';

export type RegistrationFormType = {
  phoneCode?: {
    label?: string;
    countryISO?: string;
    icon?: () => ReactNode;
    value?: { phoneCode: string; iso2Code: string };
  };
  phoneNumber?: string;
} & Omit<RegistrationSubmit, 'phoneNumber' | 'preferredLanguageId'>;

export enum errorCode {
  EMAIL_ALREADY_EXISTS = 'EmailAlreadyExists',
}

export type DataError = {
  propertyName: string;
  attemptedValue: string;
  errorMessage: string;
  errorCode: errorCode;
};

export type ErrorPayload = {
  status: number;
  data: DataError[];
};
