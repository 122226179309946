import { styled } from '@ev-ui/core/common';

export const DescriptionContainer = styled('div')<{ isRtl: boolean }>(({ isRtl }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 'auto',
  justifyContent: 'flex-start',
  overflow: 'hidden',
  direction: isRtl ? 'rtl' : 'ltr',
  marginRight: isRtl ? '20vw' : 0,
}));
