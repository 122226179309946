export const getLinkFromObject = (linksObject: any, linkName: string, language: string): string => {
  const linkIndex = Object.keys(linksObject).indexOf(linkName);

  if (linkIndex === -1) return '';

  const linkTranslations = Object.values(linksObject)[linkIndex] as any;
  const translationIndex = Object.keys(linkTranslations).indexOf(language);

  if (translationIndex === -1) return linkTranslations['en'] as string;

  return Object.values(linkTranslations)[translationIndex] as string;
};
