import { PENDO_SCRIPT_ID } from 'services/consts/analytics';
import { getEnv } from '@ecovadis/infrastructure/env';

declare global {
  interface Window {
    pendo?: {
      initialize: (config: any) => void;
    };
  }
}

type PendoData = {
  visitor: {
    id: string;
    language: string;
    product_section: string;
    productType: number;
  };
  account: {
    id: string;
  };
};
const appendScript = () => {
  const pendoKey = getEnv().REACT_APP_PENDO_API_KEY;

  if (pendoKey && !document.getElementById(PENDO_SCRIPT_ID)) {
    const script = document.createElement('script');

    script.id = PENDO_SCRIPT_ID;
    script.innerHTML = `
    (function(apiKey){
      (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];v=['initialize','identify','updateOptions','pageLoad','track'];
      for(w=0,x=v.length;w<x;++w)(function(m){o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);        
      y=e.createElement(n);y.async=!0;y.src='https://cdn.eu.pendo.io/agent/static/${pendoKey}/pendo.js';z=e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
  })('${pendoKey}');
    `;

    document.head.appendChild(script);
  }
};

export const pendoInit = (pendoData: PendoData) => {
  appendScript();

  if (window.pendo) {
    window.pendo.initialize(pendoData);
  }
};
