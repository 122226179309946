import { createReducer } from '@reduxjs/toolkit';
import { setLanguage } from './actions';
import { DEFAULT_LANGUAGE } from 'services/consts/translations';

export type LanguageState = {
  currentLanguage: string;
};

export const initialState: LanguageState = {
  currentLanguage: DEFAULT_LANGUAGE,
};

export const languageReducer = createReducer<LanguageState>(initialState, {
  [setLanguage.type]: (state, action: ReturnType<typeof setLanguage>) => ({
    ...state,
    currentLanguage: action.payload,
  }),
});
