// import { styled } from '@ecovadis/ui-kit';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ButtonProps } from './Button.types';

export const StyledButton = styled.button<ButtonProps>`
  align-items: center;
  border: 0;
  border-radius: 22px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 9px 19px;
  position: relative;
  font-family: 'Noto Sans';
  font-size: 16px;
  font-weight: bold;
  transition: all 0.15s ease-out 0s;
  width: ${props => (props?.fullWidth ? '100%' : props?.width ? `${props.width}px` : 'inherit')};
  height: 44px;
  ${props =>
    props.isDisabled
      ? css`
          cursor: not-allowed;
          opacity: 0.5;
        `
      : ``}

  ${props =>
    props.primary
      ? css`
          color: white;
          background-color: #42ad88;
          &:hover {
            box-shadow: 0 2px 3px 0 rgba(#42ad88, 0.4);
          }
        `
      : ``}  

  ${props =>
    props.secondary
      ? css`
          background-color: white;
        `
      : ``}  

  ${props =>
    props.textButton
      ? css`
          background-color: transparent;
          color: #42ad88;
          padding: 10px 0;

          &:hover {
            text-decoration: underline;
          }
        `
      : ``}
`;
