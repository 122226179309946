import React, { useMemo, useState } from 'react';
import DescriptionSection from 'features/description';
import RegistrationForm from 'features/registrationForm';
import { RegistrationFormType } from 'features/registrationForm/types';
import { mapCountryPhoneCodesToOptions } from 'features/registrationForm/utils';
import { UserRegistrationResponse } from 'model/registration/registration-v3';
import { MainContainer, PaginationContainer, ScrollImage, ScrollText, ScrollWrapper } from 'pages/Main/styles';
import { getInvitationId } from 'pages/Main/utils';
import { useSelector } from 'react-redux';

import { AppState } from 'store';
import { invitationDataSelector } from 'store/apiStore/selectors';
import { ReactComponent as PaginationOne } from '../../assets/images/svg/pagination_1.svg';
import { ReactComponent as PaginationTwo } from '../../assets/images/svg/pagination_2.svg';
import { ReactComponent as PaginationThree } from '../../assets/images/svg/pagination_3.svg';
import { ReactComponent as PaginationFour } from '../../assets/images/svg/pagination_4.svg';
import useAutoScrollToSection from 'hooks/useSectionScroll';
import { useIntl } from '@ecovadis/infrastructure-i18n-files';

const RegistrationView = () => {
  const { t } = useIntl();

  const [sectionToScrollTo, setSectionToScrollTo] = useState(1);
  const invitationId = getInvitationId(window.location.search) as string;
  const invitationInformation = useSelector((state: AppState) => invitationDataSelector(invitationId)(state));
  const invitationPrefilledData = useMemo(() => {
    if ((invitationInformation?.invitationData as UserRegistrationResponse) && invitationInformation?.countries) {
      const phoneCodeOptionValue = mapCountryPhoneCodesToOptions(invitationInformation?.countries, t).find(
        option => option.value.phoneCode === invitationInformation?.invitationData?.phoneNumber?.phoneCode,
      );

      return {
        ...invitationInformation?.invitationData,
        phoneNumber: invitationInformation?.invitationData?.phoneNumber?.phoneNumber,
        phoneCode: phoneCodeOptionValue,
      } as RegistrationFormType;
    }
  }, [invitationInformation]);

  const renderScrollView = (section: number) => {
    switch (section) {
      case 1:
        return <PaginationOne />;
      case 2:
        return <PaginationTwo />;
      case 3:
        return <PaginationThree />;
      case 4:
        return <PaginationFour />;
      default:
        return <PaginationOne />;
    }
  };

  useAutoScrollToSection({ sectionToScrollTo, setSectionToScrollTo });

  const hideLoginButton = invitationInformation?.registrationFormSettings?.hideLoginButton || false;
  const hideSubscriptionInformation = invitationInformation?.registrationFormSettings?.hideLoginButton || false;
  const isEmailMutable = invitationInformation?.registrationFormSettings?.isEmailMutable || false;
  const isCompanyNameMutable = invitationInformation?.registrationFormSettings?.isCompanyNameMutable || false;

  return (
    <MainContainer>
      <DescriptionSection hideSubscriptionInformation={hideSubscriptionInformation} />
      <RegistrationForm
        hasInvitingCompany={Boolean(invitationInformation?.invitingCompanyData?.invitingCompanyName) || false}
        countries={invitationInformation?.countries || []}
        prefilledData={invitationPrefilledData as RegistrationFormType}
        invitationId={invitationId || null}
        registrationFormSettings={{
          hideLoginButton: hideLoginButton,
          hideSubscriptionInformation: hideSubscriptionInformation,
          isEmailMutable: isEmailMutable,
          isCompanyNameMutable: isCompanyNameMutable,
        }}
      />
      <ScrollWrapper>
        <ScrollImage />
        <ScrollText>{t('portal.registration.welcome.Scroll')}</ScrollText>
      </ScrollWrapper>
      <PaginationContainer>{renderScrollView(sectionToScrollTo)}</PaginationContainer>
    </MainContainer>
  );
};

export default RegistrationView;
