import SectionContainer from 'components/common/SectionContainer/SectionContainer';
import { HeaderText, SubText } from 'components/common/SectionContainer/styles';

import { EVFirstSectionImage, SubTextContainer } from './styles';
import { ReactComponent as EvLOGO } from '../../../assets/images/svg/logo_white.svg';
import { useIntl } from '@ecovadis/infrastructure/i18n';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { isMobileView } from 'store/windowDimenionsStore/selectors';

const FirstSection = () => {
  const { t } = useIntl();

  const isMobile = useSelector((state: AppState) => isMobileView(state));

  return (
    <SectionContainer>
      <EVFirstSectionImage aria-label="" />
      <SubTextContainer>
        <HeaderText tabIndex={isMobile ? -1 : 2}>
          {t('portal.registration.welcome.welcome_to_ev_logo', {
            logo: <EvLOGO />,
          })}
        </HeaderText>
        <SubText>
          {t(
            'portal.registration.welcome.detailed_insights_for compliance_improvement_and_acceleration_on_your_sustainability_journey',
          )}
        </SubText>
      </SubTextContainer>
    </SectionContainer>
  );
};

export default FirstSection;
