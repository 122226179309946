import {
  IntlProvider as EvIntlProvider,
  IntlProviderProps as EvIntlProviderProps,
} from '@ecovadis/infrastructure-i18n-files/dist';
import { Spinner } from '@ev-ui/core/common';
import { useSelector } from 'react-redux';

import { getCurrentLanguage } from 'store/languageStore/selectors';
import { apiErrorKey, ErrorPageRoute } from '@ecovadis/infrastructure/network';

type IntlProviderProps = {
  language?: string;
  children: JSX.Element;
};

export const IntlProvider = ({ children, language }: IntlProviderProps) => {
  const languageCodeState = useSelector(getCurrentLanguage);
  const languageCode = language || languageCodeState;
  const intlProps: EvIntlProviderProps = {
    languageCode: languageCode,
    // TODO: When production, should be on CDN and fetch
    // Needed to create the dummy modules re-exporting the language messages due to limitation of Rollup
    // See: https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars
    getTranslationsData: import(`./i18n/lang-${languageCode}.ts`).then(module => module.default),
    onError: err => {
      window.location.hash = `#/?${apiErrorKey}=${ErrorPageRoute.oops}`;
      // TODO: Sentry or 24x7.com
      console.error(err);
    },
    Spinner: <Spinner />,
    children,
  };

  return <EvIntlProvider {...intlProps} />;
};
