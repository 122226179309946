import {
  NumericProductTypes,
  PENDO_ACCOUNT_UNIQUE_ID,
  PENDO_PRODUCT_SECTION,
  PENDO_VISITOR_UNIQUE_ID,
  ProductTypes,
} from 'services/consts/analytics';
import { DEFAULT_LANGUAGE } from 'services/consts/translations';
import { pendoInit } from 'services/utils/pendo';
import { PendoInputArgs } from './usePendo.types';
import { PendoProduct, PendoResponse } from 'model/pendo/pendo';
import { isPendoEnabled } from './usePendo.consts';

export const pendoInitialize = (dataLayer: PendoInputArgs) => {
  const pendoData = {
    visitor: {
      id: PENDO_VISITOR_UNIQUE_ID,
      language: DEFAULT_LANGUAGE,
      product_section: PENDO_PRODUCT_SECTION,
      productType: dataLayer.productType,
    },
    account: {
      id: PENDO_ACCOUNT_UNIQUE_ID,
    },
  };

  if (isPendoEnabled) {
    pendoInit(pendoData);
  }
};

export const mapToDataLayer = (data: PendoResponse): PendoInputArgs => ({
  productType: mapProductType(data.pendoProduct),
});

export const mapProductType = (productType?: PendoProduct): number => {
  switch (productType) {
    case ProductTypes.Unknown:
      return NumericProductTypes.Unknown;
    case ProductTypes.CsrRating:
      return NumericProductTypes.CsrRating;
    case ProductTypes.Sunrise:
      return NumericProductTypes.Sunrise;
    default:
      return NumericProductTypes.Unknown;
  }
};
