import { styled, FormControl, FormHelperText, TextField } from '@ev-ui/core/common';

type EVFormHelperTextProps = {
  isInputNumber?: boolean;
};

export const EVInputFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  height: 'fit-content',
  zIndex: 5,
  marginTop: 12,
}));

export const EVTextField = styled(TextField)(() => ({
  width: 'inherit',
}));

export const EVFormHelperText = styled('span')(({ theme }) => ({
  maxWidth: 'inherit',
  font: theme.evFonts.Noto12_Regular,
  display: 'flex',
}));
