import SectionContainer from 'components/common/SectionContainer/SectionContainer';
import { Header2Text, SubText } from 'components/common/SectionContainer/styles';

import { EVThirdSectionImage, LinkUnderlined } from './styles';
import { useIntl } from '@ecovadis/infrastructure-i18n-files';
import { useSelector } from 'react-redux';
import { getCurrentLanguage } from 'store/languageStore/selectors';
import { SUPPORT_LINKS } from 'services/consts/links';
import { getLinkFromObject } from 'services/utils/links';
const ThirdSection = () => {
  const { t } = useIntl();

  const languageCodeState = useSelector(getCurrentLanguage);
  const link = getLinkFromObject(SUPPORT_LINKS, 'legalNoticeDataProtection', languageCodeState);

  return (
    <SectionContainer id="section-3">
      <EVThirdSectionImage />
      <Header2Text>{t('portal.registration.welcome.Completecustomized')}</Header2Text>
      <SubText>{t('portal.registration.welcome.GetratedbyourexpertsCARBON')}</SubText>
      <div>
        <SubText>{t('portal.registration.welcome.Concernedaboutthesecurity')}</SubText>
        <LinkUnderlined href={link} target="_blank" rel="noopener" id="data-protection-link" tabIndex={-1}>
          <SubText>{t('portal.registration.welcome.Seehowwekeep')}</SubText>
        </LinkUnderlined>
      </div>
    </SectionContainer>
  );
};

export default ThirdSection;
