import React, { ChangeEvent, useState, useEffect } from 'react';
import { TextField, TextFieldProps as TextFieldPropsEvUi } from '@ev-ui/core/common';
import { Autocomplete, AutocompleteChangeReason, AutocompleteInputChangeReason } from '@material-ui/lab';
import { SelectOption } from './types';

export type SelectProps = {
  label?: string;
  error?: boolean;
  required?: boolean;
  options: SelectOption[];
  value?: SelectOption | null;
  onChange?: (value: SelectOption | null) => void;
  onManualChange?: (value: SelectOption | null) => void;
  onBlur?: () => void;
  onSearchStarted?: (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => void;
  id?: string | number;
  testId?: string;
} & Omit<TextFieldPropsEvUi, 'value' | 'onBlur' | 'onChange'>;

function LanguageSelect(props: SelectProps) {
  const { options, onSearchStarted, value, onChange, onBlur, error, label, onManualChange, id, testId } = props;
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (event: ChangeEvent<{}>, val: SelectOption | null, reason: AutocompleteChangeReason) => {
    if (reason === 'clear') {
      return onChange?.({ label: 'English', value: 'en' });
    }
    onChange?.(val);
    onManualChange?.(val);
  };

  useEffect(() => {
    // Remove mui input from tabulation order to acheive the required order
    const inputElement = document.querySelector('.MuiAutocomplete-input') as HTMLInputElement;

    if (inputElement) {
      inputElement.tabIndex = -1;
    }
  }, []);

  return (
    <Autocomplete<SelectOption>
      tabIndex={1}
      options={options}
      value={value}
      open={isOpen}
      aria-expanded={isOpen}
      getOptionLabel={option => option.label ?? ''}
      getOptionSelected={(option, selectedValue) => option?.value === selectedValue.value}
      renderInput={params => (
        <TextField
          tabIndex={-1}
          {...params}
          label={label}
          width={props?.width}
          fullWidth
          onBlur={onBlur}
          error={error}
          id={id}
          inputProps={{
            ...params.inputProps,
          }}
          data-testid={testId}
        />
      )}
      blurOnSelect
      fullWidth
      onChange={handleChange}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      onBlur={onBlur}
      onInputChange={onSearchStarted}
      id={id}
    />
  );
}

export default React.memo(LanguageSelect) as typeof LanguageSelect;
