import React from 'react';
import { useIntl } from '@ecovadis/infrastructure-i18n-files/dist';
import { useDispatch } from 'react-redux';
import { EvAppBarView, EvLogoBlack, LanguageContainer, NavItemContrainer } from './styles';
import { getLanguageOptionById, mapLanguagesToOptions } from './utils';

import LanguageSelect from 'components/LanguageSelect/LanguageSelect';
import { SelectOption } from 'components/LanguageSelect/types';
import { setLanguage } from 'store/languageStore/actions';

import { AppState, useSelector } from 'store';
import CompanyView from './CompanyView';
import { supportedLanguagesCodes } from './const';
import { InvitingCompanyDataView, LanguageDto } from 'model/registration/registration-v3';

type AppBarProps = {
  languages: LanguageDto[];
  invitingCompanyData?: InvitingCompanyDataView;
};
const AppBar = ({ languages, invitingCompanyData }: AppBarProps) => {
  const dispatch = useDispatch();
  const { t } = useIntl();
  const currentLanguage = useSelector((state: AppState) => state.appLanguage.currentLanguage);
  const supportedLanguages = languages.filter(lang => lang.id && supportedLanguagesCodes.includes(lang.id));
  const languagesOption = mapLanguagesToOptions(supportedLanguages, t);
  const currentOptionValue = getLanguageOptionById(currentLanguage, languagesOption);

  const handleSelectLanguage = (selectedValue: SelectOption | null) => {
    dispatch(setLanguage(selectedValue?.value as string));
  };

  return (
    <EvAppBarView>
      <EvLogoBlack />
      <NavItemContrainer>
        {invitingCompanyData?.invitingCompanyName && (
          <CompanyView
            legalEntityName={invitingCompanyData?.invitingCompanyName}
            companyLogoUrl={invitingCompanyData?.invitingCompanyLogoUrl as string}
          />
        )}
        <LanguageContainer>
          <LanguageSelect
            label={''}
            options={languagesOption}
            value={currentOptionValue}
            width={123}
            onChange={handleSelectLanguage}
          />
        </LanguageContainer>
      </NavItemContrainer>
    </EvAppBarView>
  );
};

export default AppBar;
