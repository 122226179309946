import React from 'react';
import { RouteProps } from 'react-router-dom';
import RegistrationView from 'pages/registrationView';
import ConfirmationView from 'pages/confirmationView';
import { InvitationError } from 'pages/InvitationError';
import { pageTitles } from './pageTitles';

export type RouteElement = RouteProps & {
  key?: string;
  exact?: boolean;
  title?: string;
};

export const URL = {
  CONFIRMATION_VIEW: '/confirmation/success',
  INVITATION_ERROR: '/error',
  HOME: '/',
};

export const routes: RouteElement[] = [
  {
    key: 'confirmationPage',
    path: URL.CONFIRMATION_VIEW,
    element: <ConfirmationView />,
    exact: true,
    title: pageTitles.confirmation,
  },
  {
    key: 'registrationPage',
    path: URL.HOME,
    element: <RegistrationView />,
    exact: true,
    title: pageTitles.registration,
  },
  { key: 'invitationErrorPage', path: URL.INVITATION_ERROR, element: <InvitationError />, exact: true },
];
