import { useEffect } from 'react';

interface UseAutoScrollToSectionProps {
  sectionToScrollTo: number;
  setSectionToScrollTo: (section: number) => void;
}

const useAutoScrollToSection = ({ sectionToScrollTo, setSectionToScrollTo }: UseAutoScrollToSectionProps) => {
  useEffect(() => {
    const handleWindowScroll = () => {
      const scrollTop = window.pageYOffset;
      const windowHeight = window.innerHeight;
      const scrollBottom = scrollTop + windowHeight;
      const documentHeight = document.body.scrollHeight;

      const sectionHeight = documentHeight / 4; // divide the document height into 4 sections
      const currentSection = Math.ceil(scrollTop / sectionHeight);

      if (scrollBottom === documentHeight) {
        // Scroll to the last section of the document
        const targetYOffset = documentHeight - windowHeight + 20; // add 1 pixel to smoothly scroll to the end

        window.scrollTo({ top: targetYOffset, behavior: 'smooth' });
      }

      if (currentSection !== sectionToScrollTo) {
        setSectionToScrollTo(currentSection);
      }
    };

    window.addEventListener('scroll', handleWindowScroll);

    return () => window.removeEventListener('scroll', handleWindowScroll);
  }, [sectionToScrollTo]);
};

export default useAutoScrollToSection;
