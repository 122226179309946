import { PendoProduct } from 'model/pendo/pendo';

// PENDO
export const PENDO_ACCOUNT_UNIQUE_ID = 'ACCOUNT-UNIQUE-ID';
export const PENDO_PRODUCT_SECTION = 'registration';
export const PENDO_SCRIPT_ID = 'pendo_script';
export const PENDO_VISITOR_UNIQUE_ID = 'VISITOR-UNIQUE-ID';

// HOTJAR
export const HOTJAR_SCRIPT_ID = 'hotjar_script';

export const ProductTypes: Record<string, PendoProduct> = {
  Unknown: 'Undefined',
  CsrRating: 'CsrRating',
  Sunrise: 'Sunrise',
};

export const NumericProductTypes: Record<string, number> = {
  Unknown: 0,
  CsrRating: 1,
  Sunrise: 2,
};
