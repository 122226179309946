import { useEffect } from 'react';

import { useGetV3PendoQuery } from 'model/pendo/pendo';
import { getInvitationToken } from 'services/utils/queryParams';
import { mapToDataLayer, pendoInitialize } from './usePendo.utils';
import { isPendoEnabled } from './usePendo.consts';

export const usePendo = () => {
  const invitationToken = getInvitationToken();

  const { data, isSuccess } = useGetV3PendoQuery(
    {
      invitationToken: invitationToken || '',
    },
    { skip: !isPendoEnabled },
  );

  useEffect(() => {
    if (isSuccess && data) {
      pendoInitialize(mapToDataLayer(data));

      return;
    }
  }, [isSuccess]);
};
