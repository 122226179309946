import { styled } from '@ev-ui/core/common';
import { ReactComponent as EVLogo } from '../../assets/images/ecovadis_logo_black.svg';

export const EvLogoBlack = styled(EVLogo)(() => ({
  '@media (max-width: 768px)': {
    marginLeft: '15px',
  },
}));

export const EvAppBarView = styled('nav')(({ theme }) => ({
  backgroundColor: theme.evColors.white,
  minHeight: '3.75rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 28px 0 28px',
  width: '100%',
  zIndex: 1,
  position: 'fixed',
  '@media (max-width: 768px)': {
    padding: '0px',
    height: 'auto',
  },
}));
export const NavItemContrainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '50%',
}));

export const LanguagesLabel = styled('p')(({ theme }) => ({
  font: theme.evFonts.Noto14_Bold,
  marginRight: 20,
  '@media (max-width: 768px)': {
    visibility: 'hidden',
  },
}));

export const LineSeparator = styled('hr')(({ theme }) => ({
  borderTop: `1px solid ${theme.evColors.grey300}`,
  width: '100%',
  '@media (min-width: 768px)': {
    visibility: 'hidden',
  },
}));
export const CompanyWrapper = styled('div')<{ isRtl: boolean }>(({ theme, isRtl }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  borderRight: `1px solid ${theme.evColors.grey300}`,
  marginRight: 20,
  direction: isRtl ? 'rtl' : 'ltr',
  '@media (max-width: 768px)': {
    position: 'fixed',
    flexDirection: 'row-reverse',
    marginRight: 0,
    padding: '12px 15px 12px 15px',
    marginTop: '95px',
    backgroundColor: theme.evColors.white,
    width: '100%',
    justifyContent: 'flex-end',
  },
}));

export const InviteCompanyContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

export const CompanySubText = styled('span')(({ theme }) => ({
  font: theme.evFonts.Noto12_Regular,
  color: theme.evColors.grey600,
  paddingRight: 5,
}));

export const InviteCompanyText = styled('span')(({ theme }) => ({
  font: theme.evFonts.Noto12_Bold,
  color: theme.evColors.grey600,
  paddingRight: '25px',
  zIndex: 5555,
}));

export const LanguageContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  '@media (max-width: 768px)': {
    marginRight: 15,
  },
}));

export const ImageWrapper = styled('img')(() => ({
  maxWidth: '100px',
  maxHeight: '40px',
  marginRight: '6px',
}));
