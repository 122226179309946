import React from 'react';
import FirstSection from './sections/FirstSection';
import FourthSection from './sections/FourthSection';
import SecondSection from './sections/SecondSection';
import ThirdSection from './sections/ThirdSection';

import { DescriptionContainer } from './styles';
import { useSelector } from 'react-redux';
import { isRtl } from 'store/languageStore/selectors';

export type DescriptionSectionPropTypes = {
  hideSubscriptionInformation: boolean;
};

const DescriptionSection = (props: DescriptionSectionPropTypes) => {
  const isRtlSelected = useSelector(isRtl);
  const { hideSubscriptionInformation } = props;

  return (
    <DescriptionContainer isRtl={isRtlSelected}>
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      {!hideSubscriptionInformation && <FourthSection />}
    </DescriptionContainer>
  );
};

export default DescriptionSection;
