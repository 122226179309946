import { Spinner } from '@ev-ui/core/common';
import { useBoratFeatureToggle } from '@ecovadis/feature-toggle';
import { useEffect } from 'react';
import './index.css';
import { IntlProvider } from 'components/IntlProvider';

import { Main } from 'pages/Main';
import useWindowDimenions from 'hooks/useWindowDimensions';
import { SnackbarProvider } from 'components/Snackbar';
import { usePendo } from 'hooks/usePendo/usePendo';
import { useHotjar } from 'hooks/useHotjar';

export const App = () => {
  useWindowDimenions();
  const { areFtsAvailable, fetchFeatureToggles } = useBoratFeatureToggle();

  useHotjar();
  usePendo();

  useEffect(() => {
    fetchFeatureToggles();
  }, []);

  return (
    <>
      {!areFtsAvailable ? (
        <Spinner />
      ) : (
        <IntlProvider>
          <SnackbarProvider>
            <Main />
          </SnackbarProvider>
        </IntlProvider>
      )}
    </>
  );
};
