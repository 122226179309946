import { useNavigate } from 'react-router-dom';
import { HttpStatusCode } from '@ecovadis/infrastructure/network';
import { InvitationError } from 'pages/Main/type';
import { URL as ROUTE_URL } from 'services/consts/routes';
import React, { useEffect } from 'react';

type useInvitationErrorProps = {
  invitationError: InvitationError;
};
function useInvitationError({ invitationError }: useInvitationErrorProps) {
  const navigate = useNavigate();

  useEffect(() => {
    if (invitationError?.status === HttpStatusCode.UnprocessableEntity) {
      const url = document.location.href;

      window.history.pushState({}, '', url.split('?')[0]);
      navigate(ROUTE_URL.INVITATION_ERROR, { state: { errorType: invitationError?.data?.type } });
    }
  }, [invitationError]);
}

export default useInvitationError;
