import { styled } from '@ev-ui/core/common';
import { ReactComponent as EVFirstViewImage } from '../../../assets/images/first_section_img.svg';
import { ReactComponent as EVCheckedMark } from '../../../assets/images/checkMark.svg';
import { ReactComponent as EVSecondViewImage } from '../../../assets/images/second_section_img.svg';
import { ReactComponent as EVThirdViewImage } from '../../../assets/images/third_section_img.svg';
import { ReactComponent as EVFourthViewImage } from '../../../assets/images/fourth_section_img.svg';

export const StyledEVCheckedMark = styled(EVCheckedMark)(({ theme }) => ({
  minWidth: theme.spacing(2.5),
  minHeight: theme.spacing(2.5),
}));

export const BoldSubText = styled('p')(({ theme }) => ({
  font: theme.evFonts.Noto24_Bold,
  color: theme.evColors.white,
}));

export const SubContainer = styled('li')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'baseline',
  gap: theme.spacing(2),
}));

export const ItemContainer = styled('ul')(() => ({
  maxWidth: 'inherit',
  '@media (max-width: 768px)': {
    maxWidth: '100%',
    padding: 0,
  },
}));

export const SubMainContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
  },
}));

export const SubTextContainer = styled('div')(() => ({
  width: '100%',
}));

export const LinkUnderlined = styled('a')(({ theme }) => ({
  color: theme.evColors.white,
}));

export const SubTextWrapper = styled('div')(({ theme }) => ({
  width: '25.5rem',
  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

export const EVFirstSectionImage = styled(EVFirstViewImage)(({ theme }) => ({
  width: '100%',
  '@media (max-width: 768px)': {
    maxWidth: '350px',
  },
}));

export const EVSecondSectionImage = styled(EVSecondViewImage)(() => ({
  marginTop: '60px',
  '@media (max-width: 768px)': {
    maxWidth: '150px',
  },
}));

export const EVThirdSectionImage = styled(EVThirdViewImage)(() => ({
  marginTop: '60px',
  '@media (max-width: 768px)': {
    maxWidth: '327px',
  },
}));

export const EVFourthSectionImage = styled(EVFourthViewImage)(() => ({
  marginTop: '60px',
  '@media (max-width: 768px)': {
    maxWidth: '327px',
  },
}));

export const TextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
