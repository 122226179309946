import { createReducer } from '@reduxjs/toolkit';
import { setWidowDimensions } from './actions';
import { WindowSize } from './types';

export type WidowDimensionsState = {
  currentWidowDimensions: WindowSize;
};

export const initialState: WidowDimensionsState = {
  currentWidowDimensions: { width: window.innerWidth, height: window.innerHeight },
};

export const WidowDimensionsReducer = createReducer<WidowDimensionsState>(initialState, {
  [setWidowDimensions.type]: (state, action: ReturnType<typeof setWidowDimensions>) => ({
    ...state,
    currentWidowDimensions: action.payload,
  }),
});
