import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from '@ecovadis/infrastructure/i18n';
import { HttpStatusCode } from '@ecovadis/infrastructure/network';
import {
  BoxContainer,
  FormContainer,
  FormTitle,
  LearnMoreSection,
  LinkWrapper,
  TextNotice,
  TextNoticeWrapper,
  RegularText,
  RegistrationSection,
  TitleContainer,
  PhoneNumberWrapper,
  CountryCodeWrapper,
  ButtonWrapper,
  LoginBtn,
  OuterContainer,
  Link,
} from './style';
import { FIELD } from './enum';
import InputText from 'components/common/Input/InputText';
import { StyledButton } from 'components/common/Button/Button.styles';
import {
  containsDoubleDots,
  emailAlreadyExist,
  getLoginPageUrl,
  getVitalsLoginPageUrl,
  isSunriseProductType,
  mapCountryPhoneCodesToOptions,
} from './utils';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { getCurrentLanguage, isRtl } from 'store/languageStore/selectors';
import { isEmailFormatValid, isPhoneNumberValid } from './validationResolver';
import CountryCodeSelectField from 'components/CountryCodeSelect/CountryCodeSelectField';
import { ErrorPayload, RegistrationFormType } from './types';
import { URL } from 'services/consts/routes';
import { useNavigate } from 'react-router-dom';
import { getLinkFromObject } from 'services/utils/links';
import { SUBSCRIPTION_LINKS } from 'services/consts/links';
import {
  CountryDto,
  registrationAPIV3,
  useGetV3RegistrationLoginWithInvitationTokenQuery,
  UserRegistrationFormSettingsView,
} from 'model/registration/registration-v3';
import { getInvitationToken } from 'services/utils/queryParams';
import { isMobileView } from 'store/windowDimenionsStore/selectors';
import { getEnv } from '@ecovadis/infrastructure/env';
import { Feature } from 'services/consts/features';
import { FeatureToggleOnOff, Off, On, isFeatureEnabled } from '@ecovadis/feature-toggle';

type RegistrationFormProps = {
  countries: CountryDto[];
  prefilledData: RegistrationFormType;
  invitationId: string | null;
  hasInvitingCompany?: boolean;
  registrationFormSettings: UserRegistrationFormSettingsView;
};

const RegistrationForm = ({
  countries,
  prefilledData,
  invitationId,
  hasInvitingCompany,
  registrationFormSettings,
}: RegistrationFormProps) => {
  const { t } = useIntl();
  const translate = (key: string, values?: Record<string, string | number | JSX.Element> | undefined) =>
    t(`portal.registration.welcome.${key}`, values);
  const navigate = useNavigate();
  const [triggerApi, setTriggerApi] = useState<boolean>(false);

  const preferredLanguageId = useSelector((state: AppState) => getCurrentLanguage(state));
  const isRtlSelected = useSelector(isRtl);
  const isMobile = useSelector((state: AppState) => isMobileView(state));

  const form = useForm<RegistrationFormType>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: prefilledData as RegistrationFormType,
  });

  const languageCodeState = useSelector(getCurrentLanguage);

  const subscriptionLink = getLinkFromObject(SUBSCRIPTION_LINKS, 'compareSubscriptions', languageCodeState);

  const countryCodes = mapCountryPhoneCodesToOptions(countries, t);

  const [register, { error, isError, isSuccess, isLoading, data: emailToken }] =
    registrationAPIV3.useLazyPostV3RegistrationRegistrationQuery();

  const invitationToken = getInvitationToken();
  const { error: invitationError } = useGetV3RegistrationLoginWithInvitationTokenQuery(
    { invitationToken: invitationId || '' },
    { skip: !triggerApi },
  );

  useEffect(() => {
    if (invitationError) {
      setTriggerApi(false);
    }
  }, [invitationError]);

  useEffect(() => {
    if (isError) {
      const errors = error as ErrorPayload;
      const emailExists = errors.status === HttpStatusCode.BadRequest ? emailAlreadyExist(errors) : false;

      if (emailExists) {
        form.setError(FIELD.EMAIL, {
          message: (
            <div>
              {translate('Lookslikeyoualreadyhaveanaccount', {
                /* @ts-ignore */
                b: chunks => <b>{chunks}</b>,
                /* @ts-ignore */
                a: () => renderLogin(),
              })}
            </div>
          ) as any,
        });
      }
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      navigate(URL.CONFIRMATION_VIEW, { state: { email: form.getValues().email, emailToken } });
    }
  }, [isSuccess]);

  const handleRegistration = () => {
    const { phoneNumber, phoneCode, email, firstName, lastName, companyName } = form.getValues();
    const { isValid: isFirstNameValid, msg: firstNameErrorMsg } = containsDoubleDots(firstName as string);
    const { isValid: isLastNameValid, msg: latNameErrorMsg } = containsDoubleDots(lastName as string);
    const phoneNumberValidation = isPhoneNumberValid(phoneNumber);
    const emailValidation = isEmailFormatValid(email);
    const isCompanyNameNotValid = companyName?.trim() === '';

    if (!isFirstNameValid) {
      form.setError(FIELD.FIRSTNAME, { message: translate(firstNameErrorMsg) });

      return;
    }

    if (!isLastNameValid) {
      form.setError(FIELD.LASTNAME, { message: translate(latNameErrorMsg) });

      return;
    }

    if (!emailValidation.isValid || !phoneNumberValidation.isValid) {
      if (emailValidation.message?.emailErrorMessage) {
        form.setError(FIELD.EMAIL, { message: t(emailValidation.message?.emailErrorMessage) });
      }

      if (phoneNumberValidation.message?.phoneNumberErrorMessage) {
        form.setError(FIELD.PHONE_NUMBER, { message: t(phoneNumberValidation.message?.phoneNumberErrorMessage) });
      }

      return;
    }

    if (isCompanyNameNotValid) {
      form.setError(FIELD.COMPANY_NAME, { message: translate('this_field_is_required') });

      return;
    }

    const registrationRequestBody = {
      firstName,
      lastName,
      email,
      preferredLanguageId,
      companyName,
      phoneNumber: {
        phoneCode: phoneCode?.value?.phoneCode,
        iso2Code: phoneCode?.value?.iso2Code,
        phoneNumber,
      },
    };

    const payload =
      invitationId !== null ? { ...registrationRequestBody, invitationToken: invitationId } : registrationRequestBody;

    register({ registrationSubmit: payload });
  };

  const handleLoginWithInvitationToken = () => {
    const isVitalsRegistrationFlow =
      isFeatureEnabled(Feature.TigerLoginLinkFix) && isSunriseProductType(invitationToken);

    if (isVitalsRegistrationFlow) {
      window.location.replace(getVitalsLoginPageUrl());

      return;
    }

    const redirectUrl = `${
      getEnv().REACT_APP_BFF_API_URL
    }/v3/Registration/login_with_invitation_token?invitationToken=${invitationToken}`;

    window.location.replace(redirectUrl);
  };

  const handleLoginWithSelfRegistration = () => {
    const redirectUrl = `${getEnv().REACT_APP_BFF_API_URL}/v3/Registration/login`;

    window.location.replace(redirectUrl);
  };

  const renderLogin = () =>
    invitationId ? (
      <LoginBtn onClick={handleLoginWithInvitationToken} id="login-btn" tabIndex={!isMobile ? 3 : undefined}>
        {translate('login', {
          /* @ts-ignore */
          b: chunks => <b>{chunks}</b>,
        })}
      </LoginBtn>
    ) : (
      <>
        <FeatureToggleOnOff feature={Feature.MartiansSelfRegProfileSelection}>
          <On>
            <LoginBtn onClick={handleLoginWithSelfRegistration} id="login-btn" tabIndex={!isMobile ? 3 : undefined}>
              {translate('login', {
                /* @ts-ignore */
                b: chunks => <b>{chunks}</b>,
              })}
            </LoginBtn>
          </On>
          <Off>
            <LinkWrapper
              href={getLoginPageUrl()}
              id="login-btn"
              target="_blank"
              rel="noopener noreferrer"
              tabIndex={!isMobile ? 3 : undefined}
            >
              {translate('login', {
                /* @ts-ignore */
                b: chunks => <b>{chunks}</b>,
              })}
            </LinkWrapper>
          </Off>
        </FeatureToggleOnOff>
      </>
    );

  return (
    <FormProvider {...form}>
      <OuterContainer>
        <RegistrationSection
          isRtl={isRtlSelected}
          hasInvitedCompanyDisplayed={hasInvitingCompany}
          id="foregistrationFormrm"
        >
          <FormContainer>
            <TitleContainer>
              <FormTitle>{translate('RegistrationForm')}</FormTitle>
            </TitleContainer>
            <RegularText isGrey>{translate('PleaseCompleteAllFieldsMarkedWithAnAsterisk')}</RegularText>
            <InputText
              fieldName={FIELD.FIRSTNAME}
              label={translate('Firstname')}
              required
              type="text"
              inputProps={{
                maxLength: 100,
              }}
            />
            <InputText
              fieldName={FIELD.LASTNAME}
              label={translate('Lastname')}
              required
              type="text"
              inputProps={{
                maxLength: 100,
              }}
            />
            <InputText
              fieldName={FIELD.EMAIL}
              label={translate('business_mail')}
              required
              type="email"
              disabled={registrationFormSettings?.isEmailMutable === false}
            />
            <BoxContainer>
              <CountryCodeWrapper>
                <CountryCodeSelectField
                  testId={FIELD.PHONE_CODE}
                  fieldName={FIELD.PHONE_CODE}
                  label={translate('dialing_code')}
                  required
                  options={countryCodes}
                />
              </CountryCodeWrapper>
              <PhoneNumberWrapper>
                <InputText fieldName={FIELD.PHONE_NUMBER} label={translate('PhoneNumber')} required type="number" />
              </PhoneNumberWrapper>
            </BoxContainer>
            <InputText
              fieldName={FIELD.COMPANY_NAME}
              label={translate('CompanyName')}
              required
              disabled={registrationFormSettings?.isCompanyNameMutable === false}
              type="text"
              inputProps={{
                maxLength: 500,
              }}
            />
            <ButtonWrapper isSubscriptionVisible={!registrationFormSettings.hideSubscriptionInformation}>
              <StyledButton
                data-testid="register-button"
                primary
                fullWidth
                isDisabled={isLoading}
                onClick={e => form.handleSubmit(handleRegistration)(e)}
              >
                {translate('Register')}
              </StyledButton>
            </ButtonWrapper>
            {!registrationFormSettings.hideLoginButton && (
              <TextNoticeWrapper>
                <TextNotice>{translate('Alreadyhaveanaccount')}</TextNotice>
                {renderLogin()}
              </TextNoticeWrapper>
            )}
          </FormContainer>
          {!registrationFormSettings.hideSubscriptionInformation && (
            <LearnMoreSection>
              <RegularText>
                {translate('AfterRegistrationYouWillGetAFreePreviewOfthePlatform', {
                  /* @ts-ignore */
                  b: chunks => <b>{chunks}</b>,
                })}
              </RegularText>
              <RegularText>
                {translate('ToHaveYourCompanyAssessedYouWillNeedToSelectASubscriptionPlan', {
                  /* @ts-ignore */
                  b: chunks => <b>{chunks}</b>,
                  /* @ts-ignore */
                  a: chunks => (
                    <Link
                      href={subscriptionLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      role="link"
                      id="scope_wizard_status_submitted_link"
                    >
                      {chunks}
                    </Link>
                  ),
                })}
              </RegularText>
            </LearnMoreSection>
          )}
        </RegistrationSection>
      </OuterContainer>
    </FormProvider>
  );
};

export default RegistrationForm;
