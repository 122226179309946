import SectionContainer from 'components/common/SectionContainer/SectionContainer';
import { Header2Text, SubText } from 'components/common/SectionContainer/styles';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { isMobileView } from 'store/windowDimenionsStore/selectors';

import { ItemContainer, StyledEVCheckedMark, EVSecondSectionImage, SubContainer, SubMainContainer } from './styles';
import { useIntl } from '@ecovadis/infrastructure-i18n-files';
const SecondSection = () => {
  const isMobile = useSelector((state: AppState) => isMobileView(state));
  const { t } = useIntl();
  const translate = (key: string, values?: Record<string, string | number> | undefined) =>
    t(`portal.registration.welcome.${key}`, values);

  return (
    <SectionContainer id="section-2">
      {isMobile && <EVSecondSectionImage data-testid="img-mobile" />}
      <Header2Text>{translate('we_have_already_assessed')}</Header2Text>
      <SubText>{translate('of_all_sizes_and_industries')}</SubText>
      <SubMainContainer>
        <ItemContainer>
          <SubContainer>
            <StyledEVCheckedMark />
            <SubText>{translate('BenchmarkSustainabilityPerformanceCARBON')}</SubText>
          </SubContainer>
          <SubContainer>
            <StyledEVCheckedMark />
            <SubText>{translate('ConnectWithOtherSustainabilityMindedCompCARBON')}</SubText>
          </SubContainer>
          <SubContainer>
            <StyledEVCheckedMark />
            <SubText>{translate('AccessToPrimaryGHGEmissionsCARBON')}</SubText>
          </SubContainer>
        </ItemContainer>
        {!isMobile && <EVSecondSectionImage data-testid="img-desktop" />}
      </SubMainContainer>
    </SectionContainer>
  );
};

export default SecondSection;
