import { EvTheme, styled } from '@ev-ui/core/common';

export const TitleContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(-3),
}));

export const OuterContainer = styled('div')(() => ({
  position: 'relative',
  minWidth: '28rem',
  '@media (max-width: 768px)': {
    minWidth: '100%',
  },
}));

export const RegistrationSection = styled('div')<{ isRtl: boolean; hasInvitedCompanyDisplayed?: boolean }>(
  ({ theme, isRtl, hasInvitedCompanyDisplayed }) => ({
    direction: isRtl ? 'rtl' : 'ltr',
    display: 'flex',
    justifyContent: 'center',
    height: 'fit-content',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'sticky',
    right: '5vw',
    top: '12vh',
    borderRadius: '25px',
    backgroundColor: theme.evColors.grey100,
    '@media (max-width: 768px)': {
      position: 'relative',
      right: '0vw',
      width: '100%',
      top: hasInvitedCompanyDisplayed ? '10vh' : '5vh',
      borderRadius: '0px 0px 25px 25px',
    },
  }),
);

export const FormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.evColors.white,
  padding: '0px 40px',
  borderRadius: '25px',
  width: '100%',
  '@media (max-width: 768px)': {
    borderRadius: '0px',
    width: 'inherit',
  },
}));

export const LearnMoreSection = styled('div')(() => ({
  borderRadius: '0px  0px 25px 25px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '90%',
  minHeight: '100%',
  padding: '2px 15px 15px 15px',
}));

export const FormTitle = styled('h2')(({ theme }) => ({
  font: theme.evFonts.Noto24_Bold,
  color: theme.evColors.primaryDark,
  marginTop: '1.5rem',
}));

export const TextNoticeWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
}));

export const LinkWrapper = styled('a')(({ theme }) => ({
  color: theme.evColors.primaryTurquoise,
  font: theme.evFonts.Noto12_Regular,
  textAlign: 'center',
  paddingLeft: 3,
  paddingRight: 3,
}));

export const LoginBtn = styled('button')(({ theme }) => ({
  all: 'unset',
  color: theme.evColors.primaryTurquoise,
  font: theme.evFonts.Noto12_Regular,
  textAlign: 'center',
  textDecoration: 'underline',
  paddingLeft: 3,
  paddingRight: 3,
  cursor: 'pointer',
}));

export const RegularText = styled('p')<{ isGrey?: boolean }>(({ theme, isGrey }) => ({
  font: theme.evFonts.Noto12_Regular,
  textAlign: 'center',
  color: isGrey ? theme.evColors.grey600 : undefined,
}));
export const TextNotice = styled('p')(({ theme }) => ({
  color: theme.evColors.grey500,
  font: theme.evFonts.Noto12_Regular,
  marginRight: '.3rem',
}));

export const LabelCheckBox = styled('p')(({ theme }) => ({
  font: theme.evFonts.Noto12_Regular,
}));

export const BoxContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  flexDirection: 'row',
  width: '100%',
  gap: theme.spacing(1),
  zIndex: 0,
}));

export const ButtonWrapper = styled('div')<{ isSubscriptionVisible: boolean }>(({ isSubscriptionVisible }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
  width: '100%',
  paddingTop: isSubscriptionVisible ? 40 : 30,
  paddingBottom: isSubscriptionVisible ? 0 : 40,
}));

export const PhoneNumberWrapper = styled('div')(() => ({
  width: '100%',
}));

export const CountryCodeWrapper = styled('div')(() => ({
  width: '50%',
  height: 'inherit',
}));

export const Link = styled('a')(({ theme }) => ({
  color: theme.evColors.primaryTurquoise,
  textDecoration: 'underline',
  cursor: 'pointer',
}));
