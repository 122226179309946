// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';
import { useIntl } from '@ecovadis/infrastructure-i18n-files/dist';
import { AppContainer } from './styles';
import AppBar from 'components/AppBar/AppBar';
import { useGetV3RegistrationUserRegistrationQuery } from 'model/registration/registration-v3';
import { Route, Routes } from 'react-router-dom';
import { getInvitationId } from './utils';
import { Spinner } from '@ev-ui/core/common';
import { routes, RouteElement } from 'services/consts/routes';
import useInvitationError from 'hooks/useInvitationError';
import { InvitationError } from './type';

export const Main = () => {
  const { t } = useIntl();
  const invitationToken = getInvitationId(window.location.search) as string;
  const { data, isLoading, error } = useGetV3RegistrationUserRegistrationQuery({
    invitationToken,
  });
  const registrationError = error as InvitationError;

  useInvitationError({ invitationError: registrationError });

  return (
    <>
      <AppContainer>
        {isLoading ? (
          <div data-testid="spinner">
            <Spinner />
          </div>
        ) : (
          <>
            <AppBar languages={data?.languages || []} invitingCompanyData={data?.invitingCompanyData} />

            <Routes>
              {routes.map((route: RouteElement) => (
                <Route
                  key={route.key}
                  {...route}
                  element={
                    <>
                      <Helmet title={t(`portal.registration.welcome.${route.title}`)} />
                      {route.element}
                    </>
                  }
                />
              ))}
            </Routes>
          </>
        )}
      </AppContainer>
    </>
  );
};
