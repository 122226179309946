export enum ErrorTypeEnum {
  INVITATION_ALREADY_SUBMITTED = 'invitation_already_submitted',
  NVITATION_IS_CANCELLED = 'invitation_is_cancelled',
}

export type InvitationError = {
  status: number;
  data: {
    type: ErrorTypeEnum;
    title: string;
    status: number;
    traceId: string;
  };
};
