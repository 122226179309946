import SectionContainer from 'components/common/SectionContainer/SectionContainer';
import { Header2Text, SubText, SubTextNoMargin } from 'components/common/SectionContainer/styles';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { isMobileView } from 'store/windowDimenionsStore/selectors';

import { EVFourthSectionImage, LinkUnderlined, SubMainContainer, SubTextWrapper, TextContainer } from './styles';
import { useIntl } from '@ecovadis/infrastructure/i18n';
import { getCurrentLanguage } from 'store/languageStore/selectors';
import { SUBSCRIPTION_LINKS } from 'services/consts/links';
import { getLinkFromObject } from 'services/utils/links';
const FourthSection = () => {
  const isMobile = useSelector((state: AppState) => isMobileView(state));
  const { t } = useIntl();
  const translate = (key: string, values?: Record<string, string | number> | undefined) =>
    t(`portal.registration.welcome.${key}`, values);

  const languageCodeState = useSelector(getCurrentLanguage);
  const link = getLinkFromObject(SUBSCRIPTION_LINKS, 'compareSubscriptions', languageCodeState);

  return (
    <SectionContainer id="section-4">
      {isMobile && <EVFourthSectionImage data-testid="img-mobile" />}
      <Header2Text>{translate('Subscriptionplanstailored')}</Header2Text>
      <SubMainContainer>
        <SubTextWrapper>
          <SubText>{translate('Weunderstandthateverycompanyisdifferent')}</SubText>
          <LinkUnderlined href={link} id="lean-more-about-ecovadis-link" target="_blank" rel="noopener" tabIndex={-1}>
            <SubText>{translate('LearnmoreaboutEcoVadissubscription')} </SubText>
          </LinkUnderlined>
          <TextContainer>
            <SubTextNoMargin>{translate('Stillnotsureifitsworthit')}</SubTextNoMargin>
            <SubTextNoMargin>
              <strong>{translate('Registerforfree')}</strong>
            </SubTextNoMargin>
          </TextContainer>
        </SubTextWrapper>
        {!isMobile && <EVFourthSectionImage data-testid="img-desktop" />}
      </SubMainContainer>
    </SectionContainer>
  );
};

export default FourthSection;
