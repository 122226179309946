type ErrorMessages = {
  phoneNumberErrorMessage?: string;
};

export type PhoneValidation = {
  isValid: boolean;
  message: ErrorMessages;
};

export const isPhoneNumberValid = (phoneNumber?: string): PhoneValidation => {
  if (String(phoneNumber).length < 6 || String(phoneNumber).length >= 12) {
    return {
      isValid: false,
      message: {
        phoneNumberErrorMessage: 'portal.registration.welcome.Phonenumbermustbebetween',
      },
    };
  }

  return { isValid: true, message: {} };
};

export const isEmailFormatValid = (email?: string | null) => {
  const pattern =
    /^[\w!#$%&'*+\-\/=?\\^_`{|}~]+(\.[\w!#$%&'*+\-\/=?\^_`{|}~]+)*@((([\-\w]+\.)+[a-zA-Z]{2,})|(([0-9]{1,3}\.){3}[0-9]{1,3}))$/;

  const emailValid = email && pattern.test(email);

  if (!emailValid) {
    return {
      isValid: false,
      message: {
        emailErrorMessage: 'portal.registration.welcome.EmailNotValid',
      },
    };
  }

  return { isValid: true, message: {} };
};
